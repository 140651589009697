import { StyleSheet, View, Image, TextInput, TouchableWithoutFeedback, ActivityIndicator, Text } from 'react-native';
import { useState, useEffect } from 'react';
import { auth } from '../firebaseConfig';
import { doc, collection, setDoc, addDoc, Timestamp, getDoc, updateDoc, query, where, getDocs } from "firebase/firestore"; 
import { sendSignInLinkToEmail } from "firebase/auth";
import * as ImagePicker from 'expo-image-picker';

import { db } from '../firebaseConfig';
import Button from '../components/Button';
import ImageViewer from '../components/ImageViewer';
const PlaceholderImage = require('../assets/images/select-image.png');
import { saveImage }  from '../services/storage/Storage';
import { getUserDataByUserId }  from '../services/users/Users';


export default function CreateAccountScreen({ route, navigation }) {

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [isEmailConfirmed, setIsEmailConfirmed] = useState(false);
  const [animating, setAnimating] = useState(false);
  const [saveResultMessage, setSaveResultMessage] = useState('');
  const [sentEmailConfirmMessage, setSentEmailConfirmMessage] = useState('');
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isNewUser, setIsNewUser] = useState(false);
  const [favoriteBar, setFavoriteBar] = useState('');

  // use this to determine if we should show Save or Continue
  const [isDirty, setIsDirty] = useState(false);

  const [selectedImage, setSelectedImage] = useState(null);
  const [isNewImage, setIsNewImage] = useState(false);

  useEffect(() => {
    (async () => {

      let savedUserId = window.localStorage.getItem('userId');
      if (savedUserId) {
        setIsSignedIn(true);

        // set is Dirty so it's not confusing to the user they can "Save"
        setIsDirty(true);

        const userRef = doc(db, "Users", savedUserId);
        const userSnap = await getDoc(userRef);
        if (userSnap.data().username) {
          setUsername(userSnap.data().username);
        }
        if (userSnap.data().email) {
          setEmail(userSnap.data().email);
        }
        if (userSnap.data().isEmailConfirmed) {
          setIsEmailConfirmed(userSnap.data().isEmailConfirmed);
        }
        if (userSnap.data().storageImageUrl) {
          setSelectedImage(userSnap.data().storageImageUrl);
        }
        if (userSnap.data().favoriteBar) {
          setFavoriteBar(userSnap.data().favoriteBar);
        }
      }
      else {
        navigation.setOptions({ title: 'Sign in or Create an account' });
      }

      // new user
      console.log('route.params', route.params);
      if (route != null && route.params != null && route.params.email) {
        setEmail(route.params.email);
        setIsNewUser(true);
        navigation.setOptions({ title: 'Edit Profile' });        
      }      

    })();
  }, [])

  let actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: 'https://barswim.com/?me=', //'', //'https://atabar-d7693.web.app/', //'https://www.example.com/finishSignUp?cartId=1234',
    //url: 'http://localhost:19006/?me=',
    // This must be true.
    handleCodeInApp: true,
    // iOS: {
    //   // TODO
    //   bundleId: 'com.example.ios'
    // },
    // android: {
    //   // TODO
    //   packageName: 'com.example.android',
    //   installApp: true,
    //   minimumVersion: '12'
    // },
    //dynamicLinkDomain: 'barswim.page.link'
  };

  const sendSignInEmail = async (e) => {
    e.preventDefault();

    if (email == null || email.trim() == '')
    {
      return;
    }

    actionCodeSettings.url = actionCodeSettings.url + btoa(email);

    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem('emailForSignIn', email);
        // console.log("email sent?");
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
        console.log("error", errorCode, errorMessage);
      });

      setSentEmailConfirmMessage('Email sent.  Please check your email to login.');
  }

  const handleUsernameSubmit = async (e) => {
    e.preventDefault();

    // show activity indicator
    setSaveResultMessage('');
    setAnimating(true);

    // check if user name is taken
    async function getUserByUsername() {

      const q = query(collection(db, "Users"), where('userNameLowerCase', "==" , username.toLowerCase()));

      const querySnapshot = await getDocs(q);
      var userIdWithUsername = null;
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        //console.log(doc.id, " => ", doc.data());
        userIdWithUserName = doc.id;
      });
      return userIdWithUsername;

    }
    let usernameTakenByUserId = await getUserByUsername();
    //console.log('usernameTakenByUserId=', usernameTakenByUserId);

    // check if user has a User record
    let savedUserId = window.localStorage.getItem('userId');
    if (usernameTakenByUserId != null && savedUserId && usernameTakenByUserId != savedUserId)
    {
      setSaveResultMessage('Sorry, ' + username + ' is taken.  Please choose another.');
      setAnimating(false);
      console.log('username is taken', username);
      return;
    }

    var userInfo = window.localStorage.getItem('userId') != null ? await getUserDataByUserId(window.localStorage.getItem('userId')) : null;
    var needToCreateUser = false;
    if (!userInfo || userInfo == null || userInfo == undefined) {
      needToCreateUser = true;
      //alert("user does not exist, need to create one");
    }    

    if (!savedUserId || needToCreateUser)
    {
      // create a User record
      try {
        const docRef = await addDoc(collection(db, 'Users'), {
          username: username,
          usernameLowerCase: username.toLowerCase(),
          created: Timestamp.now(),
          email: email,
          emailLowerCase: email.toLowerCase(),
          isEmailConfirmed: true
        });
        //console.log("User written with ID: ", docRef.id);
        window.localStorage.setItem('userId', docRef.id);
        setIsNewUser(false);
        setIsSignedIn(true);
      } catch (err) {
        alert(err)
      }
    }
    else
    {
      // update the User record
      console.log('update the user');

      try {
        const userRef = doc(db, "Users", savedUserId);

        // update the User
        // TODO: probably shouldn't update if not "dirty"
        await updateDoc(userRef, {
          username: username,
          usernameLowerCase: username.toLowerCase(),
          email: email,
          favoriteBar: favoriteBar,
        });

        console.log('User updated');
      } catch (e) {
        alert(err);
        console.log("Error update the user: ", e);
      }      
    }

    // if(!isEmailConfirmed && email != null && email != '')
    // {
    //   // send email confirmation
    //   sendEmailConfirmation(email);
    //   setSentEmailConfirmMessage('Email sent.  Please check your email to confirm your account.');
    // }

    // update user record with picture.  doing this after record is created or updated.
    if (selectedImage != null && isNewImage)
    {
      // var storageImageRef = "";
      // var storageImageUrl = "";
      var saveImageResult = await saveImage(selectedImage);
      var storageImageRef = saveImageResult.storageImageRef;
      var storageImageUrl = saveImageResult.storageImageUrl;

      try {
        const userRef = doc(db, "Users", savedUserId);

        // update the User image
        await updateDoc(userRef, {
          storageImageRef: storageImageRef,
          storageImageUrl: storageImageUrl,
        });

        console.log('User image updated');
      } catch (e) {
        alert(err);
        console.log("Error update the user image: ", e);
      }
    }

    setSaveResultMessage('Saved! Your user name is ' + username);
    window.localStorage.setItem('username', username);
    setIsDirty(false);

    // hide activity indicator
    setAnimating(false);

    //let savedUsername = window.localStorage.getItem('username');
    //console.log("savedUsername:", savedUsername);

  }

  /*
  const sendEmailConfirmation = async (emailToSend) => {

    sendSignInLinkToEmail(auth, emailToSend, actionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem('emailForSignIn', email);
        // console.log("email sent?");
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
        console.log("error", errorCode, errorMessage);
      });
  }
  */

  const pickImageAsync = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      allowsEditing: true,
      quality: 1,
    });

    if (!result.canceled) {
      setSelectedImage(result.assets[0].uri);
      setIsNewImage(true);
      setIsDirty(true);
      //console.log(result.assets[0].uri);
      //let testing64string = result.assets[0].uri;     
    } else {
      alert('You did not select an image.');
    }
  };

  return (
    <View style={styles.container}>

      {!isSignedIn && !isNewUser &&
        <View>
          <Text style={{ fontSize: 18 }}>To Sign in or Create an account, enter your email</Text>
          <TextInput
            style={{ height: 40, width: 300, backgroundColor: '#fff', padding: 5, marginTop: 10, marginBottom: 10 }}
            placeholder="Enter your email"
            placeholderTextColor="gray"
            onChangeText={newText => setEmail(newText)}
            defaultValue={email}
            textAlignVertical={"center"}
            maxLength={40}
          />

          <Button theme="basic" label="Send Me A Link" onPress={sendSignInEmail} />
          <Text style={{ color: '#674FE0', width: 300, marginTop: 20 }}>{sentEmailConfirmMessage}</Text>

          <Text style={{ fontSize: 12, marginTop: 20 }}>We never display your email to others or share it.  It is used only for logging in or creating an account.</Text>

        </View>
      }
      {isNewUser &&
        <View>
          <Text style={{ fontSize: 18 }}>Enter a username to finish creating your account</Text>
          <TextInput
              style={{ height: 40, width: 300, backgroundColor: '#fff', padding: 5, marginTop: 10, marginBottom: 10 }}
              placeholder="Enter your username"
              placeholderTextColor="gray"
              onChangeText={newText => setUsername(newText)}
              defaultValue={username}
              textAlignVertical={"center"}
              maxLength={40}
            />
            <Button theme="basic" label="Create Account" onPress={handleUsernameSubmit} />
            <Text style={{ color: '#674FE0' }}>{saveResultMessage}</Text>
        </View>
      }
      {isSignedIn && !isNewUser &&
        <View>

          <Text style={{ fontSize: 18 }}>User name</Text>
          <View style={styles.textContainer}>
            <TextInput
              style={{ height: 40, width: 300, backgroundColor: '#fff', padding: 5 }}
              placeholder="Enter your username"
              placeholderTextColor="gray"
              onChangeText={newText => setUsername(newText)}
              onChange={() => setIsDirty(true)}
              defaultValue={username}
              textAlignVertical={"center"}
              maxLength={40}
            />
            <Text style={{ color: '#674FE0' }}>{saveResultMessage}</Text>
          </View>

          <View style={styles.imageContainer}>
            <Text style={{ fontSize: 18 }}>User image</Text>
            <TouchableWithoutFeedback onPress={pickImageAsync}>
              <View style={styles.imageContainer2}>
                <ImageViewer
                  placeholderImageSource={PlaceholderImage}
                  selectedImage={selectedImage}
                />
              </View>
            </TouchableWithoutFeedback>
            {/* <Button theme="secondary" label="Choose a photo (optional)" onPress={pickImageAsync} /> */}
          </View>

          <Text style={{ fontSize: 18, paddingTop: 20 }}>Email</Text>
          <Text style={{ fontSize: 18 }}>{email}</Text>

          <Text style={{ fontSize: 18, paddingTop: 20 }}>Favorite Bar?</Text>
          <View style={styles.textContainer}>
            <TextInput
              style={{ height: 40, width: 300, backgroundColor: '#fff', padding: 5 }}
              placeholder="Enter your favorite bar (optional)"
              placeholderTextColor="gray"
              onChangeText={newText => setFavoriteBar(newText)}
              onChange={() => setIsDirty(true)}
              defaultValue={favoriteBar}
              textAlignVertical={"center"}
              maxLength={40}
            />
          </View>
          {/* <View style={styles.textContainer}>
            <TextInput
              style={{ height: 40, width: 300, backgroundColor: '#fff', padding: 5 }}
              placeholder="Enter your email and click save to sign in"
              placeholderTextColor="gray"
              onChangeText={newText => setEmail(newText)}
              defaultValue={email}
              textAlignVertical={"center"}
              maxLength={40}
            />
            <Text style={{ color: '#674FE0', width: 300 }}>{sentEmailConfirmMessage}</Text>
          </View> */}
          {/* 
      <View style={{ flexDirection: 'row' }}>
        <Text style={{ fontSize: 18, paddingRight: 10 }}>Email status:</Text><Text style={{ fontSize: 18, color: '#674FE0' }} onPress={() => handleSubmit('')}>Unverified</Text>
      </View>
      <View style={{ flexDirection: 'row' }}>
        <Text style={{ fontSize: 12 }}>Secure your account by verifying your email.  Your email is not shared or displayed to other users.</Text>
      </View> */}


          {/* <Text style={{ fontSize: 16, color: '#674FE0' }} onPress={() => handleBarNameClick(item.data.bar)}>{item.data.bar}</Text> */}



          <ActivityIndicator size="large" color="#ffd33d" animating={animating} hidesWhenStopped={true} />

          { isDirty ?
              <Button theme="basic" label="Save" onPress={handleUsernameSubmit} />
              :
              <Button theme="basic" label="Continue" onPress={() => navigation.navigate('HomeScreen')} />
          }
        </View>
      }






      {/* <View style={styles.textContainer}>
        <TextInput
          style={{ height: 40, width: 300, backgroundColor: '#fff' }}
          placeholder="Enter your email"
          onChangeText={newText => setEmail(newText)}
          defaultValue={email}
          textAlignVertical={"center"}
        />
      </View>

      <Button theme="createaccount" label="Send Email" onPress={handleSubmit} /> */}

    </View>
  );

}

const styles = StyleSheet.create({
    container: {
      paddingTop: 20,
      paddingLeft: 10,
      paddingRight: 10,
      flex: 1,
      backgroundColor: '#F2F2F2',
      alignItems: 'center',
    },
    textContainer: {
      // flex: 1 / 3,
      // flexDirection: 'row',
      // //alignItems: 'center',
      paddingBottom: 15,
    },
    imageContainer: {
      // flex: 1 / 3,
      paddingBottom: 20,
      height: 120,
      //backgroundColor: 'yellow',
    },
    imageContainer2: {
      // flex: 1 / 3,
      height: 100,
      width: 100,
    },
  });