import Modal from "react-native-modal";
import { StyleSheet, View, Button, Text } from 'react-native'; //Modal
import FontAwesome from "@expo/vector-icons/FontAwesome";

// TODO: perhaps delete this component and use the one from react-native-modal
export default function ConfirmModal({ message, visible, dismiss, confirmDelete }) {

  return (
    <Modal
      isVisible={visible}
      hasBackdrop={true}
      backdropColor="#fff"
      backdropOpacity={0.9}
      style={{ margin: 50 }}
      // transparent={transparent}
      // onRequestClose={dismiss}
    >
      {/* <TouchableWithoutFeedback onPress={dismiss}>
        <View style={styles.modalOverlay} />
      </TouchableWithoutFeedback> */}

      <View style={{ width: 250 }}>
        <FontAwesome
          name="exclamation-circle"
          size={24}
          color="#25292e"
          style={styles.buttonIcon}
        />
        <Text style={styles.modalText}>{message}</Text>

        <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginTop: 20 }}>
          <Button title="No" onPress={dismiss} />
          <Button title="Yes, Delete" onPress={confirmDelete} />
        </View>
      </View>
    </Modal>
  );
}

 
const styles = StyleSheet.create({ 
  buttonLabel: {
    color: '#fff',
    fontSize: 16,
  },
});
