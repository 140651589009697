import Modal from "react-native-modal";
import { StyleSheet, View, Button, Text, Image } from 'react-native'; //Modal
import FontAwesome from "@expo/vector-icons/FontAwesome";
import { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';


// TODO: perhaps delete this component and use the one from react-native-modal
export default function ProfileModal({ message, visible, dismiss, userInfo }) {

  const [username2, setUsername2] = useState('');
  const [selectedImage2, setSelectedImage2] = useState(null);
  const [favoriteBar, setFavoriteBar] = useState('');

  useEffect(() => {
    (async () => {
      // const userRef2 = doc(db, "Users", userId);
      // const userSnap2 = await getDoc(userRef2);
      if (userInfo) {
        console.log('userInfo: ', userInfo);
        if (userInfo.username) {
          setUsername2(userInfo.username);
          console.log('userInfo.username: ', userInfo.username);
        }
        if (userInfo.storageImageUrl) {
          setSelectedImage2(userInfo.storageImageUrl);
        }

        // reset between profile clicks
        setFavoriteBar('');

        const userRef = doc(db, "Users", userInfo.userId);
        const userSnap = await getDoc(userRef);
        if (userSnap.data().favoriteBar) {
          setFavoriteBar(userSnap.data().favoriteBar);
        }
      }
    })();
  }, [userInfo])

  return (
    <Modal
      isVisible={visible}
      hasBackdrop={true}
      backdropColor="#EBBB40"
      backdropOpacity={0.6}
      onBackdropPress={dismiss}
      //style={{ width: 200, minHeight: 200 }}
      // transparent={transparent}
      // onRequestClose={dismiss}
    >
      {/* <TouchableWithoutFeedback onPress={dismiss}>
        <View style={styles.modalOverlay} />
      </TouchableWithoutFeedback> */}

      <View style={{ width: 250, height: 250, backgroundColor: 'white', alignSelf: 'center' }}>
        <View style={{ flex: 1, flexDirection: 'column', alignContent: 'center' }}>
          <Text style={{ fontSize: 16, width: '100%', textAlign: 'center' }}>{username2}</Text>
          <Image source={{ uri: selectedImage2 }} style={styles.userImage2} />
          {favoriteBar &&
            <Text style={{ fontSize: 16, width: '100%', textAlign: 'center' }}>Favorite Bar: {favoriteBar}</Text>
          } 
        </View>
      </View>
    </Modal>
  );
}

 
const styles = StyleSheet.create({ 
  buttonLabel: {
    color: '#fff',
    fontSize: 16,
  },
  userImage2: {
    width: '100%',
    height: 200,
    //borderRadius: 40/2,
    //marginRight: 5,
  },
  // modalBackdrop: {
  //   position: fixed;
  //   top: 0;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  //   background-color: rgba(0, 0, 0, 0.4);
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   z-index: 2;
  // }
  
  // .modal-content {
  //   width: 60%;
  //   min-height: 200px;
  //   background-color: white;
  //   padding: 25px;
  // }
  
});
