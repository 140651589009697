import { StyleSheet, View, Image, TextInput, Text, ActivityIndicator } from 'react-native';
import { useState, useEffect } from 'react';
import { doc, collection, setDoc, addDoc, Timestamp, updateDoc, getDoc } from "firebase/firestore"; 
import { db } from '../firebaseConfig';
import Button from '../components/Button';

export default function CommentScreen({ route, navigation }) {

    const [postId, setPostId] = useState(null);
    const [text, setText] = useState('');
    const [animating, setAnimating] = useState(false);
    const [isUpdatingComment, setIsUpdatingComment] = useState(false);
    const [commentId, setCommentId] = useState(null);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

    useEffect(() => {
        (async () => {   
            
            let savedUserId = window.localStorage.getItem('userId');
            if (!savedUserId) {
                alert("Sign in to add a comment");
                navigation.navigate('CreateAccountScreen');
            }
            
            setPostId(route.params.postId);
            if (typeof route.params.data === "object")
            {
                //console.log("in edit mode");
                setIsUpdatingComment(true);
                setCommentId(route.params.data.id);
                setText(route.params.data.data.comment);
                navigation.setOptions({ title: 'Edit comment' });
                setPostId(route.params.postId);
            }           
            
            //console.log('route.params.data:', route.params.data);
            //console.log('data:', data);

        })();
    }, [])

    /* function to add new post to firestore */
  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsSubmitDisabled(true);

    if (text == null || text.length == 0)
    {
      navigation.goBack();
      return;
    }

    // show activity indicator
    setAnimating(true);
    
    if(!isUpdatingComment)
    {
        try {

            console.log('postId:', postId);

            const addComment = async (comment, username, userId) => {
                const reviewRef = doc(collection(db, "Posts", postId, "Comments"))
                await setDoc(reviewRef, {
                    id: reviewRef.id, // Assign the autogenerated ID of the review document to the 'id' field
                    comment,
                    username,
                    userId,
                    created: Timestamp.now()
                })
            }

            await addComment(text, window.localStorage.getItem('username'), window.localStorage.getItem('userId'));

            // update the comment count on the doc
            const postRef = doc(db, "Posts", postId);
            const postSnap = await getDoc(postRef);

            console.log('postSnap.data().commentCount', postSnap.data().commentCount);

            if (postSnap.data().commentCount) {
                await updateDoc(postRef, {
                    commentCount: postSnap.data().commentCount + 1
                });
            }
            else {
                await updateDoc(postRef, {
                    commentCount: 1
                });
            }

        } catch (err) {
            setIsSubmitDisabled(false);
            alert(err);
        }
    }
    else
    {
        try {

            //const postRef = doc(db, "Posts", postId);
        const reviewRef = doc(db, "Posts", postId, "Comments", commentId);

        await updateDoc(reviewRef, {
              comment: text,
              username: window.localStorage.getItem('username')
            });
        } catch (err) {
            setIsSubmitDisabled(false);
            alert(err);
        }


    }
    
    // hide activity indicator
    setAnimating(false);
    setIsSubmitDisabled(false);
    navigation.goBack();
  }


    return (
        <View style={styles.container}>
            <TextInput
                style={{ height: 100, width: '100%', backgroundColor: '#fff' }}
                placeholder="Type here ..."
                placeholderTextColor="gray"
                onChangeText={newText => setText(newText)}
                defaultValue={text}
                numberOfLines={5}
                textAlignVertical={"top"}
                textBreakStrategy={"highQuality"}
                multiline={true}
                maxLength={500}
            />
            <View style={styles.savesection}>
                {/* <Button
                    title="Save"
                    // style={{ alignItems: 'center', justifyContent: 'center' }}
                    onPress={handleSubmit}
                /> */}
                <Button theme="basic" label="Add Comment" onPress={handleSubmit} isDisabled={isSubmitDisabled} />
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        //backgroundColor: '#25292e',
        alignItems: 'top',
        padding: 20
    },
    savesection: {
        padding: 20
    }

});

  