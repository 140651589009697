import { StyleSheet, View, Image, TextInput, Text, FlatList, SafeAreaView, ActivityIndicator, ScrollView } from 'react-native';
import { useState, useEffect, useCallback } from 'react';
import * as ImagePicker from 'expo-image-picker';
import { doc, collection, getDocs, setDoc, addDoc, Timestamp, query, where, orderBy } from "firebase/firestore"; 
import { ref, uploadBytes, uploadString, getDownloadURL } from "firebase/storage";
import FontAwesome from "@expo/vector-icons/FontAwesome";
import { MenuProvider, Menu, MenuOptions, MenuOption, MenuTrigger } from 'react-native-popup-menu';
import { FAB, Chip } from "@react-native-material/core";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
// import { StatusBar } from 'expo-status-bar';
import Swiper from 'react-native-web-swiper';

import { db } from '../firebaseConfig';
import { storage } from '../firebaseConfig';
import moment from "moment";
import { getDistanceFromLatLonInMiles }  from '../services/utils/General'

// import Button from '../components/Button';
import ImageViewer from '../components/ImageViewer';
const PlaceholderImage = require('../assets/images/background-image.png');
import Button from '../components/Button';


export default function DetailsScreen({ route, navigation }) {
 

  const [username, setUsername] = useState(null);
  const [comment, setComment] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [post, setPost] = useState(null);
  const [hasMultipleImages, setHasMultipleImages] = useState(false);
  const [comments, setComments] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [userImageUrl, setUserImageUrl] = useState(null);
  const [bar, setBar] = useState(null);
  const [data, setData] = useState(null);
  const [location, setLocation] = useState(null);
  //const [created, setCreated] = useState(null);

  var { userId } = '';
  var { postId } = '';
  //var { created } = null;

  //console.log("image url:", data.storageImageUrl);

  async function fetchData() {

    // const q = query(collection(db, "Posts"), orderBy('created', 'desc'));

    // const querySnapshot = await getDocs(q);
    // setPosts(querySnapshot.docs.map(doc => ({
    //   id: doc.id,
    //   data: doc.data()
    // })));

    // setLoading(false);
    console.log("route.params.data.id:", route.params.data.id);
    var path = 'Posts/' + route.params.data.id + '/Comments';
    console.log("path:", path);

    const q = 
      query(
        collection(db, 'Posts/' + route.params.data.id + '/Comments'),
        orderBy('created', 'asc')
      );

    const querySnapshot = await getDocs(q);

    setComments(querySnapshot.docs.map(doc => ({
      id: doc.id,
      data: doc.data()
    })));

  };

  useEffect(() => {

    (async () => {
      setLoading(true);
      
      let savedUserId = window.localStorage.getItem('userId');
      if (savedUserId) {
        userId = savedUserId;
      }

      postId = route.params.data.id;

      

      //await fetchData();

      
      var data = route.params.data.data;
      //TODO: switch all to using this?
      setData(data);

      setUsername(data.username);
      setComment(data.comment);
      setImageUrl(data.storageImageUrl);
      setPost(route.params.data);
      setUserImageUrl(data.userInfo.storageImageUrl);
      setBar(data.bar);
      //setCreated(data.created);
      
      if (route.params.location) {
        setLocation(route.params.location);
      }

      
      console.log('data.created', data.created);

      if (data.storageImageUrl && data.storageImageUrl2)
      {
        setHasMultipleImages(true);
      }

      //setLoading(false);

      // listen for a navigation back.  this also runs on first load.
      const focusHandler = navigation.addListener('focus', () => {
        (async () => {

          await fetchData();
          setLoading(false);

        })();
      });

    })();
  }, [])

  async function handleAddComment(postId) {
    let savedUserId = window.localStorage.getItem('userId');
    if (!savedUserId) {
      alert("Sign in to comment on posts.");
      navigation.navigate('CreateAccountScreen');
    }
    else {
      navigation.navigate('CommentScreen', { data: postId, postId: postId});
    }
  }

  const renderComment = useCallback(({ item }) => (
    <View style={{ padding: 5, backgroundColor: '#fff', borderColor: '#F2F2F2', borderWidth: 3, borderRadius: 5 }}>
      <View style={{ flexDirection: 'row' }}>
        <Text style={{ flex: 1}}>{item.data.username}</Text>
        <View>
          <Menu>
            <MenuTrigger>
              <FontAwesome
                name="ellipsis-v"
                size={20}
                color="#25292e"
                style={styles.buttonIcon}
              />
            </MenuTrigger>
            {userId == item.data.userId &&
              <MenuOptions>
                <MenuOption onSelect={() => navigation.navigate('CommentScreen', { data: item, postId: postId })} text='Edit' />
              </MenuOptions>
            }
            {userId != item.data.userId &&
              <MenuOptions>
                <MenuOption onSelect={() => console.log(`Not called`)} disabled={true} text='Future menu option' />
              </MenuOptions>
            }
          </Menu>
        </View>
      </View>
      
      <Text>{item.data.comment}</Text>



    </View>
), []);

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView style={{ flex: 1, padding: 5, backgroundColor: '#F2F2F2' }}>
      {isLoading ? <></> :
        (<><View style={{ flexDirection: 'row', marginBottom: 15 }}>
          {userImageUrl &&
            <Image source={{ uri: userImageUrl }} style={styles.userImage} />}
          <View style={{ flex: 1, flexDirection: 'column', marginLeft: 30, justifyContent: 'center' }}>
            <Text style={{ fontSize: 18, textAlign: 'left' }}>{bar}</Text>
            <Text style={{ color: '#ccc', marginRight: 10, flex: 1, flexDirection: 'row', textAlign: 'left', }}>
              {moment(data.created.toDate()).format("h A")} {moment(data.created.toDate()).format('dddd')} {moment(data.created.toDate()).fromNow()}
            </Text>
            {/* <Text style={{ color: '#ccc', marginRight: 5 }}>
              {getDistanceFromLatLonInMiles(location?.coords.latitude, location?.coords.longitude, data.latitude, data.longitude).toFixed(2)} miles
            </Text> */}
          </View>
        </View>
        <View style={{ flexDirection: 'row' }}>
        <Text style={{ fontSize: 18, fontWeight: '200', textAlign: 'left', paddingLeft: 5, paddingBottom: 5, paddingRight: 5 }}>{username}:</Text><Text style={{ fontSize: 18, textAlign: 'left' }}>{comment}</Text>
        </View></>)}
        
        {hasMultipleImages && data.storageImageUrl3 && data.storageImageUrl3.length > 0 &&
          <View style={styles.imageRow}>
            <Swiper
              controlsProps={{
                dotsPos: 'bottom',
                prevPos: false, /* hide prev button */
                nextPos: false, /* hide next button */
                cellsStyle: {
                  'bottom': { position: 'absolute', bottom: -20, zIndex: 1000 },
                  'top-left': { position: 'absolute', top: 0, left: 0 },
                },
              }}
            >
              <View style={styles.imageContainer}>
                <Image source={data.storageImageUrl} style={styles.image} />
              </View>
              <View style={styles.imageContainer}>
                <Image source={data.storageImageUrl2} style={styles.image} />
              </View>
              <View style={styles.imageContainer}>
                <Image source={data.storageImageUrl3} style={styles.image} />
              </View>
            </Swiper>
          </View>
        }
         {hasMultipleImages && !data.storageImageUrl3 &&
          <View style={styles.imageRow}>
            <Swiper
              controlsProps={{
                dotsPos: 'bottom',
                prevPos: false, /* hide prev button */
                nextPos: false, /* hide next button */
                cellsStyle: {
                  'bottom': { position: 'absolute', bottom: -20, zIndex: 1000 },
                  'top-left': { position: 'absolute', top: 0, left: 0 },
                },
              }}
            >
              <View style={styles.imageContainer}>
                <Image source={data.storageImageUrl} style={styles.image} />
              </View>
              <View style={styles.imageContainer}>
                <Image source={data.storageImageUrl2} style={styles.image} />
              </View>
            </Swiper>
          </View>
        }
        {!hasMultipleImages &&
          <View style={styles.imageContainer}>
            <Image source={imageUrl} style={styles.image} />
          </View>
        }

        {isLoading ? <ActivityIndicator size="large" color="#ffd33d" animating={isLoading} hidesWhenStopped={true} /> :
          (
            <View style={{ flex: 1, flexDirection: 'column', justifyContent: 'space-between', marginTop: 5 }}>
              <FlatList
                data={comments}
                keyExtractor={({ id }, index) => id}
                renderItem={renderComment}
                style={{ flex: 1 }}
              />
            </View>
          )}

        
      </ScrollView>
      {/* <View style={{ alignItems: 'center', justifyContent: 'center', padding: 10 }}>
        <Button theme="basic" label="Add a comment" onPress={() => navigation.navigate('CommentScreen', { data: post.id, postId: post.id })} />
      </View>   */}
      {/* <StatusBar style="auto" /> */}
      {isLoading ? <></> :
          (<FAB
            variant="standard"
            icon={props => <Icon name="plus" {...props} />}
            //label=""
            color="#674FE0"
            style={styles.fab}
            onPress={() => handleAddComment(post.id)}
          />)}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F2F2F2',
    //alignItems: 'top',
    padding: 20
  },
  main: {
    flex: 1,
    //flexDirection: 'column',
    backgroundColor: '#yellow',
    //alignItems: 'top',
  },
  imageRow: {
    //paddingTop: 5,
    //alignContent: 'center',
    //flex: 1,
    //flexDirection: 'row',
    width: '100%',
    height: undefined,
    aspectRatio: 3/2.15,
  },
  imageContainer: {
    //paddingTop: 5,
    //alignContent: 'center',
    //flex: 1,
    //flexDirection: 'row',
    width: '100%',
    height: undefined,
    aspectRatio: 3/2,
    paddingBottom: 10,
  },
  // footerContainer: {
  //   flex: 1 / 8,
  //   alignItems: 'center',
  // },
  image: {
    // flex: 1,
    // flexDirection: 'row',
    width: '100%',
    height: undefined,
    aspectRatio: 3 / 2,
  },
  fab: {
    position: 'absolute',
    bottom: 16,
    right: 16,
  },
  userImage: {
    width: 40,
    height: 40,
    borderRadius: 40/2,
    marginRight: 5,
  },
});

