import * as Location from 'expo-location';


export function generateGuid() {
    //uuidv4
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

export async function getLocationPermissionStatus() {
    let { status } = await Location.getForegroundPermissionsAsync();
    return status;
}

export async function getLocation() {
    let { status } = await Location.requestForegroundPermissionsAsync();
    if (status !== 'granted') {
        // TODO: how to re-request
        //alert('Permission to access location was denied');
        //const test = Location.useForegroundPermissions();

        // console.log("status:", status);
        // console.log("requestPermission:", requestPermission);
        //alert('Please allow location access.');
        //window.location.href = "app-settings:location";
        return;
    }

    let location = await Location.getCurrentPositionAsync({});
    return location;
}

export async function getLocationMinneapolis() {
    
    //var location = new Location.LocationObject();
    //let location = await Location.getCurrentPositionAsync({});
    //let location = await Location.geocodeAsync("19 W 15th St, Minneapolis, MN", {});
    //let location = new Location.LocationData.LocationObject();
    //location.coords = new Location.LocationData.Coo();
    // location.coords.latitude = 44.9778;
    // location.coords.longitude = -93.2650;

    let location = {
        coords: { 
            latitude: 44.9778,
            longitude: -93.2650
        }
    }
    return location;
}

export function getDistanceFromLatLonInMiles(lat1, lon1, lat2, lon2) {
    //console.log("lat1, lon1, lat2, lon2", lat1, lon1, lat2, lon2);
    let distance = getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) * 0.621371;

    if (distance < 5)
        return distance.toFixed(1);
    else
        return distance.toFixed(0);
}

export function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    var R = 6371; // km
    var dLat = toRad(lat2 - lat1);
    var dLon = toRad(lon2 - lon1);
    var lat1 = toRad(lat1);
    var lat2 = toRad(lat2);

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
}

// Converts numeric degrees to radians
function toRad(Value) 
{
    return Value * Math.PI / 180;
}

// export async function getLocationFromGoogle () {

//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         //body: JSON.stringify({ postName: 'React updates ' })
//     };

//     try {
//         await fetch(
//             'https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyBQmpsFNXkLFhuWyxeJMjUW-pk7O4xOHN4', requestOptions)
//             .then(response => {
//                 response.json()
//                     .then(data => {
//                         console.log("data:", data);
//                         return data;
//                     });
//             })
//     }
//     catch (error) {
//         console.error(error);
//     }
// }
//     return fetch('https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyBQmpsFNXkLFhuWyxeJMjUW-pk7O4xOHN4', {
//         method: 'POST',
//         headers: {
//             Accept: 'application/json',
//             'Content-Type': 'application/json',
//         },

//     }).then(response => response.json())
// .then(json => {
//             return json;
//         })
//         .catch(error => {
//             console.error(error);
//         });
