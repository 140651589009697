import { StyleSheet, View, Image, TextInput, TouchableWithoutFeedback, SafeAreaView, ActivityIndicator, Text, Pressable } from 'react-native';
import { useCallback, useState, useEffect, useRef } from 'react';
import { auth } from '../firebaseConfig';
import { doc, collection, setDoc, addDoc, Timestamp, updateDoc, query, where, getDocs } from "firebase/firestore"; 
import { sendSignInLinkToEmail } from "firebase/auth";
// import MapView from 'react-native-maps';
// TODO: mobile should be this library i think: @react-navigation/native
import { GoogleMap, LoadScript, Marker, MapMouseEvent, InfoWindow } from '@react-google-maps/api';
import Ionicons from '@expo/vector-icons/Ionicons';
import FontAwesome from "@expo/vector-icons/FontAwesome";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
// const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

import { db } from '../firebaseConfig';
import Button from '../components/Button';

export default function LocationScreen({ route, navigation }) {

  const containerStyle = {
    width: '100%',
    height: '100%'
  };
  
  const center = {
    lat: route.params.location.coords.latitude,
    lng: route.params.location.coords.longitude
  };

  //var map; // = useRef<google.maps.Map>(undefined);

  // const [username, setUsername] = useState('');
  // const [email, setEmail] = useState('');
  // const [animating, setAnimating] = useState(false);
  // const [saveResultMessage, setSaveResultMessage] = useState('')
  const [isLoading, setIsLoading] = useState(true);
  const [map, setMap] = useState(null);
  const [location, setLocation] = useState(null);
  const [selectedElement, setSelectedElement] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [showInfoWindow, setInfoWindowFlag] = useState(true);
  const [activeKey, setActiveKey] = useState(null);

  // const { isLoaded } = useJsApiLoader({
  //   id: 'google-map-script',
  //   googleMapsApiKey: "AIzaSyBQmpsFNXkLFhuWyxeJMjUW-pk7O4xOHN4"
  // })

  useEffect(() => {

    // let savedUsername = window.localStorage.getItem('username');
    // //console.log("savedUsername:", savedUsername);
    // if (savedUsername) {
    //   //console.log("savedUsername:", savedUsername);
    //   setUsername(savedUsername);
    // }

    // const onLoad = React.useCallback(function callback(map) {
    //   // This is just an example of getting and using the map instance!!! don't just blindly copy!
    //   const bounds = new window.google.maps.LatLngBounds(center);
    //   map.fitBounds(bounds);
  
    //   setMap(map)
    //   setIsLoading(false);
    // }, [])
  
    // const onUnmount = React.useCallback(function callback(map) {
    //   setMap(null)
    // }, [])

    if (route.params.location)
    {
      setLocation(route.params.location);
    }
    console.log("location:", route.params.location);

    if (route.params.postsInView) {
      console.log("postsInView:", route.params.postsInView);
    }
    
  }, [])

  // const onLoad = useCallback(function callback(m) {
  //   map.current = m;
  //   setIsLoading(false);
  // }, []);

  const onLoad = useCallback(function callback(m) {
    //map.current = m;
    setMap(m)
  }, []);

  const onClick = (e) => {
    //e.latLng.lat(), e.latLng.lng());
    // let locationResult = {
    //   coords: {
    //     latitude: e.latLng.lat(),
    //     longitude: e.latLng.lng()
    //   }
    // }
    // setLocation(locationResult);
  }

  async function setLocationHeaderButton(locationResult) {
    //console.log("setLocationHeaderButton", locationResult);
    navigation.setOptions({
      headerLeft: () => (
        <Pressable onPress={() => navigation.navigate('HomeScreen', { location: locationResult })} style={{ paddingLeft: 15 }}>
          <Ionicons name="arrow-back-outline" size={30} color="#25292e" />
        </Pressable>
      )
    });
  }

  const onDrag = (e) => {
    console.log("onDrag:", e.latLng.lat(), e.latLng.lng());
    let locationResult = {
      coords: {
        latitude: e.latLng.lat(),
        longitude: e.latLng.lng()
      }
    }
    setLocation(locationResult);
    setLocationHeaderButton(locationResult);
  }

  const defaultMapOptions = {
    //styles: mapStyles.styles,
    mapTypeControl: false,
    //gestureHandling: "greedy"
  };

  //const image = <Ionicons name="arrow-back-outline" size={30} color="#25292e" />; //"https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png";
  const image = "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png";
  const customMarker = {
    path: "M29.395,0H17.636c-3.117,0-5.643,3.467-5.643,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759   c3.116,0,5.644-2.527,5.644-5.644V6.584C35.037,3.467,32.511,0,29.395,0z M34.05,14.188v11.665l-2.729,0.351v-4.806L34.05,14.188z    M32.618,10.773c-1.016,3.9-2.219,8.51-2.219,8.51H16.631l-2.222-8.51C14.41,10.773,23.293,7.755,32.618,10.773z M15.741,21.713   v4.492l-2.73-0.349V14.502L15.741,21.713z M13.011,37.938V27.579l2.73,0.343v8.196L13.011,37.938z M14.568,40.882l2.218-3.336   h13.771l2.219,3.336H14.568z M31.321,35.805v-7.872l2.729-0.355v10.048L31.321,35.805",
    fillColor: "red",
    fillOpacity: 2,
    strokeWeight: 1,
    rotation: 0,
    scale: 1,
  };

  return (
    <SafeAreaView style={{ flex: 1 }}>
      {/* {isLoading ? <ActivityIndicator size="large" color="#ffd33d" animating={isLoading} hidesWhenStopped={true} /> :
        ( */}
      <LoadScript
        googleMapsApiKey="AIzaSyBQmpsFNXkLFhuWyxeJMjUW-pk7O4xOHN4"
      //onLoad={onLoad}
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={12}
          onLoad={onLoad}
          onClick={onClick}
          options={defaultMapOptions}
        //defaultOptions={{mapTypeControl: false}} //didn't work
        // onUnmount={onUnmount}
        >
          <Marker
            // icon={{
            //   url: home_peach_new,
            //   anchor: new window.google.maps.Point(12, 24)
            // }}
            onClick={() => console.log("You clicked me!")}
            position={{ lat: location != null ? location.coords.latitude : route.params.location.coords.latitude, lng: location != null ? location.coords.longitude : route.params.location.coords.longitude }}
            draggable={true}
            onDragEnd={onDrag}
          />
          {route.params.postsInView.map((post, index) => (
            <Marker
              key={index}
              position={{ lat: post.item.data.latitude, lng: post.item.data.longitude }}
              // icon={{
              //   path: '../assets/images/map-marker-svgrepo-com.svg',
              // }}
            // TODO: this svg file has "created with Sketch" ... should check that out ...
            //   icon={{
            //     url: './../../assets/images/map-marker-svgrepo-com.svg',
            // }}
            icon={"http://maps.google.com/mapfiles/ms/icons/purple-dot.png"}
              //icon={customMarker}
              title='Click to zoom'
              onClick={(props, marker) => {
                setSelectedElement(post);
                setActiveMarker(marker);
                setActiveKey(index);
              }}
              >
              {selectedElement && activeKey == index ? (
                <InfoWindow
                  key={index}
                  visible={activeKey === index}
                  marker={activeMarker}
                  onCloseClick={() => {
                    setSelectedElement(null);
                    setActiveKey(null);
                  }}
                >
                  <div>
                    <h3>{post.item.data.bar}</h3>
                  </div>
                </InfoWindow>
              ) : null}
              </Marker>
          ))}
          

          {/* {data.map((place, index) => (
          <p key={index} onMouseOver={() => this.onMouseOverEvent(place)}>
            {place.name}
          </p>
        ))} */}

          <></>
        </GoogleMap>
      </LoadScript>
      {/* )} */}
    </SafeAreaView>
  );

}

const styles = StyleSheet.create({
  container: {
    paddingTop: 20,
    flex: 1,
    backgroundColor: '#25292e',
    alignItems: 'center',
  },
});