import { StyleSheet, View, Image, TextInput, TouchableWithoutFeedback, ActivityIndicator, Text } from 'react-native';
import { useState, useEffect } from 'react';
import { auth } from '../firebaseConfig';
import { doc, collection, setDoc, addDoc, Timestamp, updateDoc, query, where, getDocs } from "firebase/firestore"; 
import { sendSignInLinkToEmail } from "firebase/auth";

import { db } from '../firebaseConfig';
import Button from '../components/Button';

export default function UserSummaryScreen({ navigation }) {

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [animating, setAnimating] = useState(false);
  const [saveResultMessage, setSaveResultMessage] = useState('')

  useEffect(() => {

    let savedUsername = window.localStorage.getItem('username');
    //console.log("savedUsername:", savedUsername);
    if (savedUsername) {
      //console.log("savedUsername:", savedUsername);
      setUsername(savedUsername);
    }

  }, [])


  return (
    <View style={styles.container}>

      <View style={styles.rowContainer}>
        <View style={styles.rowItem}>
          <div>19 Bar</div>
          <div>Pools, Darts, Patio</div>
          <div>3pm - 2am (Mon-Fri)</div>
          <div>1pm - 2am (Sat-Sun)</div>
          <div>Open for Pride</div>
          <div>no cover.  cash only bar.</div>
        </View>
        <View style={styles.rowItem}>
          <div>The Saloon Bar</div>
          <div>Food, Dancing, Shows</div>
          <div>12pm - 2am (Sun-Thurs)</div>
          <div>12pm - 3am (Fri-Sat)</div>
          <div>Pride Block Party</div>
          <div>tickets available</div>
        </View>
      </View>
      <View style={styles.rowContainer}>
        <View style={styles.rowItem}>
          <div>Lush Lounge & Theater</div>
          <div>Hours vary</div>
        </View>
        <View>
          <div><br></br></div>
        </View>
      </View>
      <View>
        <Text style={{ color: '#fff' }}>Check back for more events</Text>
      </View>
    </View>
  );

}

const styles = StyleSheet.create({
    container: {
      paddingTop: 20,
      flex: 1,
      backgroundColor: '#25292e',
      alignItems: 'center',
    },
    rowContainer: {
      flex: 1,
      flexDirection: 'row',
      alignContent: 'flex-start',
      gap: 30,
      color: '#fff',
      maxHeight: 150,
    },
    textContainer: {
      // flex: 1 / 3,
      // flexDirection: 'row',
      // //alignItems: 'center',
      paddingBottom: 30,
    },
  });