import { Platform, StyleSheet, View, Image, Text, FlatList, TouchableOpacity, Pressable, SafeAreaView, ActivityIndicator, Dimensions } from 'react-native';
import { db } from '../firebaseConfig';
import { getFirestore, doc, collection, getDoc, getDocs, query, onSnapshot, where, orderBy, Timestamp, updateDoc, arrayUnion, arrayRemove, FieldValue, limit, startAfter } from 'firebase/firestore';
import { useState, useEffect, useCallback, useRef } from 'react';
import { auth } from '../firebaseConfig';
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import FontAwesome from "@expo/vector-icons/FontAwesome";
import moment, { max } from "moment";
// import { MenuProvider } from 'react-native-popup-menu';
import { MenuProvider, Menu, MenuOptions, MenuOption, MenuTrigger } from 'react-native-popup-menu';
import { RefreshControl } from 'react-native-web-refresh-control'
import Button from '../components/Button';
import { FAB } from "@react-native-material/core";
import Chip from '@mui/material/Chip';
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import Ionicons from '@expo/vector-icons/Ionicons';
import ProfileModal from '../components/ProfileModal';
import DistanceFilterModal from '../components/DistanceFilterModal';
import SearchFilterModal from '../components/SearchFilterModal';
//const geohash = require('ngeohash');
import geohash from 'ngeohash';
import Swiper from 'react-native-web-swiper';
import { updateUserEmailToConfirmed, getUserDataByUserId, getUserIdByEmail }  from '../services/users/Users';
import { getLocation, getDistanceFromLatLonInMiles, getLocationMinneapolis, getLocationPermissionStatus }  from '../services/utils/General'
import ContactUsModal from './ContactUsModal';
const LogoImage = require('../assets/images/logo.png');

// import PostListItem from '../components/PostListItem';

export default function HomeScreen({ route, navigation }) {
    const [isLoading, setLoading] = useState(true);
    const [posts, setPosts] = useState([]);
    const [extraData, setExtraData] = useState(new Date()); //this is used to trigger a re-render for the Flatlist on a reaction click
    const [location, setLocation] = useState(null);
    //const [lastRetrievedDate, setLastRetrievedDate] = useState(null);
    const [isRefreshing, setIsRefreshing] = useState(false);
    //var { userId } = '';
    const [userId, setUserId] = useState(null);
    const [userRole, setUserRole] = useState('');
    //const [isUsingDefaultLocation, setIsUsingDefaultLocation] = useState(false);
    const [barFilter, setBarFilter] = useState('');
    const [tagFilter, setTagFilter] = useState([]);
    const [usernameFilter, setUsernameFilter] = useState('');
    const [isProfileModalVisible, setIsProfileModalVisible] = useState(false);
    const [isDistanceFilterModalVisible, setIsDistanceFilterModalVisible] = useState(false);
    const [profileUserInfo, setProfileUserInfo] = useState(null);
    const [showSetLocationPermission, setShowSetLocationPermission] = useState(true);
    const [searchDistanceGeohashLength, setSearchDistanceGeohashLength] = useState(4);
    const [isSearchFilterModalVisible, setIsSearchFilterModalVisible] = useState(false);
    const [isContactUsModalVisible, setIsContactUsModalVisible] = useState(false);
    let defaultDistanceGeoHashLength = 4;
    let locationTemp = null;
    let postsInView = [];
    
    async function checkForEmailLink() {
        // see if it's a sign-in link click. should this be in useEffect?
        var isLink = isSignInWithEmailLink(auth, window.location.href)
        console.log("isLink", isLink);
        if (Platform.OS === 'web' && isLink) {

            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const continueUrl = urlParams.get('continueUrl');

            // console.log("queryString:", queryString);
            // console.log("urlParams for me:", urlParams.getAll("me"));
            // console.log("continueUrl", continueUrl);

            //navigation.navigate('CreateAccountScreen');

            console.log("in sign in link logic");
            let email = window.localStorage.getItem('emailForSignIn');
            if (!email) {
              // User opened the link on a different device. To prevent session fixation
              // attacks, ask the user to provide the associated email again. For example:
              //email = window.prompt('Please provide your email for confirmation');
              email = atob(urlParams.get('me'));
            }
            // The client SDK will parse the code from the link for you.

            if (email) {

                //window.localStorage.removeItem('emailForSignIn');

                signInWithEmailLink(auth, email, window.location.href)
                    .then(async (result) => {
                        // Clear email from storage.
                        window.localStorage.removeItem('emailForSignIn');

                        // See if a user exists corresponding to this email.
                        console.log("email:", email);
                        let existingUserId = await getUserIdByEmail(email);

                        if (existingUserId) {
                            console.log("existingUserId:", existingUserId);
                            window.localStorage.setItem('userId', existingUserId);
                            setUserId(existingUserId);
                            window.history.replaceState({}, document.title, "/");
                            await setUserProfileInfo();
                            alert("welcome back!");
                        }
                        else
                        {
                            console.log("no existing user found");

                            // we are in a web only section, so this should be OK for now
                            window.history.replaceState({}, document.title, "/");
                           
                            // send new sign up to create account screen
                            navigation.navigate('CreateAccountScreen', { email: email });
                        }

                        // let savedUserId = window.localStorage.getItem('userId');
                        // if (savedUserId) {
                        //     //alert("updating email to confirmed " + savedUserId + " " + email);
                        //     console.log("updating email to confirmed", savedUserId, email);
                        //     updateUserEmailToConfirmed(savedUserId, email).then(() => {window.location.replace("/");alert("Email address confirmed.");}).catch((error) => {console.log("error:", error);})
                        // }
                        // else {

                        // }
                    })
                    .catch((error) => {
                        console.log("error:", error);
                    });
            }
        }
    }

    // default values do appear to work (at least in the case of newGeohashLength)
    async function fetchData(barName = '', tags = [], username = '', newGeohashLength = -1, isManualSearch = false) {
        
        setIsRefreshing(true);
        setLoading(true);
        //setPosts([]);

        console.log("barName:", barName);
        console.log("tags:", tags);
        console.log("username:", username);

        console.log('location in fetchdata', location);
        console.log('locationTemp in fetchdata', locationTemp);
        //const center = [locationTemp.coords.latitude, locationTemp.coords.longitude];
        //const radiusInM = 5 * 1000;

        let geohashLength = searchDistanceGeohashLength != null && searchDistanceGeohashLength >= 0 && searchDistanceGeohashLength <= 6 ? searchDistanceGeohashLength : defaultDistanceGeoHashLength;
        if (newGeohashLength >= 0) {
            geohashLength = newGeohashLength;
        }

        const locationToUse = locationTemp ? locationTemp : location;
        console.log("searchDistanceGeohashLength", searchDistanceGeohashLength);
        console.log("geohashLength", geohashLength);

        // distance search
        let hashstring = geohash.encode(locationToUse.coords.latitude, locationToUse.coords.longitude);
        let buckets = geohash.neighbors(hashstring.substring(0, geohashLength));
        buckets.push(hashstring.substring(0, geohashLength));
        console.log("buckets:", buckets);

        const promises = [];

        // this is not clever
        if (geohashLength == 0) {
            buckets = [''];
        }
               
        for (const bucket of buckets) {
            if (barName) {          
                if (bucket == '') {
                    const q = query(collection(db, "Posts"), where("bar", "==", barName), where('status', 'in', [['ok']]), orderBy('created', 'desc'), limit(10));
                    promises.push(getDocs(q));
                }
                else {
                    const q = query(collection(db, "Posts"), where("geohash" + geohashLength, "==", bucket), where("bar", "==", barName), where('status', 'in', [['ok']]), orderBy('created', 'desc'), limit(10));
                    promises.push(getDocs(q));
                }
            }
            else if (tags && tags.length > 0) {
                if (bucket == '') {
                    const q = query(collection(db, "Posts"), where("tagsArray", "array-contains-any", tags), where('status', 'in', [['ok']]), orderBy('created', 'desc'), limit(10));
                    promises.push(getDocs(q));
                }
                else {
                    const q = query(collection(db, "Posts"), where("geohash" + geohashLength, "==", bucket), where("tagsArray", "array-contains-any", tags), where('status', 'in', [['ok']]), orderBy('created', 'desc'), limit(10));
                    promises.push(getDocs(q));
                }
            }
            else if (username) {
                if (bucket == '') {
                    const q = query(collection(db, "Posts"), where("username", "==", username), where('status', 'in', [['ok']]), orderBy('created', 'desc'), limit(10));
                    promises.push(getDocs(q));
                }
                else {
                    const q = query(collection(db, "Posts"), where("geohash" + geohashLength, "==", bucket), where("username", "==", username), where('status', 'in', [['ok']]), orderBy('created', 'desc'), limit(10));
                    promises.push(getDocs(q));
                }
            }
            else {
                if (bucket == '') {
                    console.log("no bucket");
                    const q = query(collection(db, "Posts"), where('status', 'in', [['ok']]), orderBy('created', 'desc'), limit(10));
                    promises.push(getDocs(q));
                }
                else {
                    const q = query(collection(db, "Posts"), where("geohash" + geohashLength, "==", bucket), where('status', 'in', [['ok']]), orderBy('created', 'desc'), limit(10));
                    promises.push(getDocs(q));
                }
            }
        }

        const snapshots = await Promise.all(promises);

        let matchingDocs = [];
        for (const snap of snapshots) {
            for (const doc of snap.docs) {
                // const lat = doc.get('lat');
                // const lng = doc.get('lng');

                // // We have to filter out a few false positives due to GeoHash
                // // accuracy, but most will match
                // const distanceInKm = geofire.distanceBetween([lat, lng], center);
                // const distanceInM = distanceInKm * 1000;
                // if (distanceInM <= radiusInM) {
                    matchingDocs.push(doc);
                // }
            }
        }

        matchingDocs.sort(function (a, b) {
            return b.data().created.seconds - a.data().created.seconds
        });

        setPosts(matchingDocs.map(doc => ({
            id: doc.id,
            data: doc.data()
        })));

        console.log("matchingDocs:", matchingDocs);

        if (matchingDocs.length < 5 && geohashLength > 0 && !isManualSearch) {
            console.log('not enough docs try again');
            // try again with a smaller geohash
            if (geohashLength == 2) {
                geohashLength = 0;
            }
            else{
                geohashLength = geohashLength - 1;
            }
            setSearchDistanceGeohashLength(geohashLength);
            await fetchData(barName, tags, username, geohashLength);
            return;
        }

        //setLastRetrievedDate(posts[posts.length - 1].data.created.toDate().toLocaleDateString());
        setIsRefreshing(false);
        setLoading(false);
    }

    async function fetchMoreData() {

        console.log("fetchMoreData");
        setIsRefreshing(true);

        //const geohashLength = 4;
        const geohashLength = searchDistanceGeohashLength != null && searchDistanceGeohashLength >= 0 && searchDistanceGeohashLength <= 6 ? searchDistanceGeohashLength : defaultDistanceGeoHashLength;
        const locationToUse = location ? location : locationTemp;

        let hashstring = geohash.encode(locationToUse.coords.latitude, locationToUse.coords.longitude);
        let buckets = geohash.neighbors(hashstring.substring(0, geohashLength));
        buckets.push(hashstring.substring(0, geohashLength));

        const promises = [];

        // this is not clever
        if (geohashLength == 0) {
            buckets = [''];
        }
       
        for (const bucket of buckets) {
            if (barFilter) {
                if (bucket == '') {
                    const q = query(collection(db, "Posts"), where("bar", "==", barFilter), where('status', 'in', [['ok']]), orderBy('created', 'desc'), limit(10), startAfter(posts[posts.length - 1].data.created));
                    promises.push(getDocs(q));
                }
                else {
                    const q = query(collection(db, "Posts"), where("geohash" + geohashLength, "==", bucket), where("bar", "==", barFilter), where('status', 'in', [['ok']]), orderBy('created', 'desc'), limit(10), startAfter(posts[posts.length - 1].data.created));
                    promises.push(getDocs(q));
                }   
            }
            else if (tagFilter && tagFilter.length > 0) {
                if (bucket == '') {
                    const q = query(collection(db, "Posts"), where("tagsArray", "array-contains-any", tagFilter), where('status', 'in', [['ok']]), orderBy('created', 'desc'), limit(10), startAfter(posts[posts.length - 1].data.created));
                    promises.push(getDocs(q));
                }
                else {
                    const q = query(collection(db, "Posts"), where("geohash" + geohashLength, "==", bucket), where("tagsArray", "array-contains-any", tagFilter), where('status', 'in', [['ok']]), orderBy('created', 'desc'), limit(10), startAfter(posts[posts.length - 1].data.created));
                    promises.push(getDocs(q));
                }
            }
            else if (usernameFilter) {
                if (bucket == '') {
                    const q = query(collection(db, "Posts"), where("username", "==", usernameFilter), where('status', 'in', [['ok']]), orderBy('created', 'desc'), limit(10), startAfter(posts[posts.length - 1].data.created));
                    promises.push(getDocs(q));
                }
                else {
                    const q = query(collection(db, "Posts"), where("geohash" + geohashLength, "==", bucket), where("username", "==", usernameFilter), where('status', 'in', [['ok']]), orderBy('created', 'desc'), limit(10), startAfter(posts[posts.length - 1].data.created));
                    promises.push(getDocs(q));
                }   
            }
            else {
                if (bucket == '') {
                    const q = query(collection(db, "Posts"), where('status', 'in', [['ok']]), orderBy('created', 'desc'), limit(10), startAfter(posts[posts.length - 1].data.created));
                    promises.push(getDocs(q));
                }
                else {
                    const q = query(collection(db, "Posts"), where("geohash" + geohashLength, "==", bucket), where('status', 'in', [['ok']]), orderBy('created', 'desc'), limit(10), startAfter(posts[posts.length - 1].data.created));
                    promises.push(getDocs(q));
                }
            }
        }

        const snapshots = await Promise.all(promises);

        let matchingDocs = [];
        for (const snap of snapshots) {
            for (const doc of snap.docs) {
                // const lat = doc.get('lat');
                // const lng = doc.get('lng');

                // // We have to filter out a few false positives due to GeoHash
                // // accuracy, but most will match
                // const distanceInKm = geofire.distanceBetween([lat, lng], center);
                // const distanceInM = distanceInKm * 1000;
                // if (distanceInM <= radiusInM) {
                    matchingDocs.push(doc);
                // }
            }
        }

        matchingDocs.sort(function (a, b) {
            return b.data().created.seconds - a.data().created.seconds
        });

        const newPosts = matchingDocs.map(doc => ({
            id: doc.id,
            data: doc.data()
        }));

        setPosts([...posts, ...newPosts]);

        setIsRefreshing(false);

        //setLastRetrievedDate(posts[posts.length - 1].data.created.toDate().toLocaleDateString());
        //setLoading(false);
    }

    async function getLocationFromGoogle () {

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            //body: JSON.stringify({ postName: 'React updates ' })
        };
    
        try {
            const response = await fetch(
                'https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyBQmpsFNXkLFhuWyxeJMjUW-pk7O4xOHN4', requestOptions);
                

            const data = await response.json();

            let locationResult = {
                coords: {
                    latitude: data.location.lat,
                    longitude: data.location.lng
                }
            };
            console.log("locationResult here:", locationResult);
            setLocation(locationResult);
            setLocationHeaderButton(locationResult);
            //setIsUsingDefaultLocation(false);
            console.log("locationResult:", locationResult);
            return locationResult;    

        }
        catch (error) {
            console.error(error);
        }
    }

    async function setDeviceLocation(userHasClickedToTurnOnLocation = false) {
        console.log("setDeviceLocation here");

        if (Platform.OS === 'web') {

            let hasRequestedPermission = window.localStorage.getItem('hasRequestLocationPermission');
            if (!hasRequestedPermission && !userHasClickedToTurnOnLocation) {
                // we want to hold off on requesting permission until the user clicks to turn it on
                // set location in that method, since it isn't working to get the result back here (at least not yet)
                console.log("hasRequestedPermission", hasRequestedPermission);
                await getLocationFromGoogle();
            }
            else
            {
                window.localStorage.setItem('hasRequestLocationPermission', true);
                setShowSetLocationPermission(false);
                var locationResult = await getLocation();
                if (locationResult) {
                    setLocation(locationResult);
                    // this is for showing the location navigation button in the header, not the permission thing
                    await setLocationHeaderButton(locationResult);
                }
                else {
                    await getLocationFromGoogle();
                }

            }

            await fetchData();

            //window.localStorage.setItem('userId', existingUserId);
            //window.localStorage.removeItem('userId');
        }


        // var locationResult = await getLocation();
        // if (locationResult) {
        //     setLocation(locationResult);
        //     await setLocationHeaderButton(locationResult);
        //     //setIsUsingDefaultLocation(false);
        //     //props.setAppLocation(locationResult);
        //     return locationResult;
        // }
        // else {
        //     //setIsUsingDefaultLocation(true);
        //     var googleLocation = await getLocationFromGoogle();
        //     console.log("googleLocation:", googleLocation);
        //     //props.setAppLocation(googleLocation);

        //     // locationResult = await getLocationMinneapolis();
        //     // setLocation(locationResult);
        //     return googleLocation;
           
        // }
        
    }

    async function setUserProfileInfo() {

        let savedUserId = window.localStorage.getItem('userId');
        console.log("savedUserId here:", savedUserId);
        if (savedUserId) {
            var userInfo = await getUserDataByUserId(savedUserId);
            if (userInfo && userInfo.storageImageUrl) {
                navigation.setOptions({
                    headerRight: () => (
                        <Pressable onPress={() => navigation.navigate('CreateAccountScreen')} style={{ paddingRight: 15 }}>
                            <Image source={{ uri: userInfo.storageImageUrl }} style={{ width: 40, height: 40, borderRadius: 40/2 }} />
                        </Pressable>
                    )
                });
            }
            if (userInfo && userInfo.role) {
                console.log("userInfo.role:", userInfo.role);
                setUserRole(userInfo.role);
            }
        }
    }

    async function setLocationHeaderButton(locationResult, postsInView = null) {
        console.log("setLocationHeaderButton", locationResult);
        locationTemp = locationResult;
        navigation.setOptions({
            headerLeft: () => (
                <Pressable onPress={() => navigation.navigate('LocationScreen', { location: locationResult, postsInView: postsInView })} style={{ paddingLeft: 15 }}>
                  <Ionicons name="location-sharp" size={30} color="#25292e" />
                </Pressable>
            )
        });
    }

    function LogoTitle() {
        return (
            <TouchableOpacity onPress={() => openContactUsModal()}>
            <Image
                style={{ width: 114, height: 32 }}
                source={LogoImage}
            />
            </TouchableOpacity>
        );
    }

    const openContactUsModal = async () => {
        // console.log("openContactUsModal");
        // setIsContactUsModalVisible(true);
    }

    const closeContactUsModal = async () => {
        setIsContactUsModalVisible(false);
    }

    async function setHeaderLogoClick() { 
        navigation.setOptions({
            headerTitle: props => <LogoTitle {...props} />
        });
    }

    /* function to get all tasks from firestore in realtime */
    useEffect(() => {
        (async () => {

            // window.localStorage.removeItem('userId');
            // return;

            // if (!auth.currentUser)
            // {
            //     console.log("no auth.currentUser");
            //     window.localStorage.removeItem('userId');
            // }

            await checkForEmailLink();

            let savedUserId = window.localStorage.getItem('userId');
            console.log("savedUserId:", savedUserId);
            if (savedUserId) {

                let user = await getUserDataByUserId(savedUserId);
                console.log("user:", user);
                if (user && user.emailLowerCase) {
                    setUserId(savedUserId);
                    await setUserProfileInfo();
                    
                }
                else {
                    // user not found, so remove the saved user id
                    window.localStorage.removeItem('userId');
                }
            }

            // // listen for a navigation back.  this also runs on first load.
            // const focusHandler = navigation.addListener('focus', () => {
            //     (async () => {

            //     })();
            // });

            if (route.params != null && route.params.location) {
                // user navigated back from location page
                console.log("route.params.location:", route.params.location);
                setLocation(route.params.location);
                setLocationHeaderButton(route.params.location);                
                await fetchData();
            }
            else {
                await setDeviceLocation();
            }

            setHeaderLogoClick();                       
        })();
    }, [route])

    //const handleReaction = async (postid, reactionValue) => {
    async function handleReaction (postid, reactionValue) {

        console.log('posts', posts);

        //let savedUserId = window.localStorage.getItem('userId');
        if (!userId) {
            alert("Sign in to react to posts.");
            navigation.navigate('CreateAccountScreen');
        }
        //console.log('userid:', userId);

        console.log('itemid:', postid, 'reactionValue:', reactionValue);

        //beer=1,heart=2,thumbsUp=3,thumbsDown=4
        var reactionName = '';
        switch (reactionValue) {
            case 1:
                reactionName = 'beer';
                break;
            case 2:
                reactionName = 'heart';
                break;
            case 3:
                reactionName = 'thumbsUp';
                break;
            case 4:
                reactionName = 'thumbsDown';
                break;
            default:
                break;
        }

        try {
                
             // get post, look at reactions in javascript. if found one for userid, do arrayRemove.  otherwise just do arrayUnion.
            const postRef = doc(db, "Posts", postid);
            const postSnap = await getDoc(postRef);

            console.log('postSnap.data().reactions', postSnap.data().reactions);

            if (postSnap.data().reactions && postSnap.data().reactions.length > 0)
            {
                var reactions = postSnap.data().reactions;
                const found = reactions.find(element => element.userId  == userId);

                console.log('found', found);

                if (found) {
                    if (found.reactionName == reactionName) {
                        console.log('no change so no update needed');
                        return;
                    }

                    // remove the old reaction
                    var oldReaction = {
                        userId: userId,
                        reactionValue: found.reactionValue,
                        reactionName: found.reactionName
                    };

                    console.log('oldReaction', oldReaction);

                    await updateDoc(postRef, {
                        reactions: arrayRemove(oldReaction)
                    });
                }

            }
                   
            var reaction = {
                userId: userId,
                reactionValue: reactionValue,
                reactionName: reactionName
            };
        
            // Atomically add a new reaction to the "reaction" array field.
            await updateDoc(postRef, {
                reactions: arrayUnion(reaction)
            });

            // update list without refetching.  reminder: posts is only available here cuz i passed it in to the renderItem (last line) - otherwise useCallback keeps the state at time of render I believe.
            const postSnap2 = await getDoc(postRef);  //can re-use postRef from above
            var newPosts = posts;
            newPosts.find(x => x.id == postid).data.reactions = postSnap2.data().reactions;
            setPosts(newPosts);
            setExtraData(new Date());
            
        } catch (err) {
          alert(err)
        }
        
      }

    const handleBarNameClick = async (barName) => {
        //console.log('barName what:', barName);
        setTagFilter([]);
        setBarFilter(barName);
        setUsernameFilter('');
        await fetchData(barName);
    }

    const clearBarFilter = async () => {
        console.log('about to clear bar filter');
        setBarFilter('');
        setTagFilter([]);
        await fetchData();
    }

    const handleTagClick = async (tag) => {
        //console.log('tag filter clicked:', tag);

       
        //console.log('tagFilter before concat:', tagFilter);
        const newTagsArray = tagFilter.concat(tag);
        setTagFilter(newTagsArray);
        setBarFilter('');
        setUsernameFilter('');
        //console.log('newTagsArray:', newTagsArray);
        //setTagFilter(tag);
        await fetchData('', newTagsArray);
    }

    const clearTagFilter = async () => {
        //console.log('about to clear tag filter');
        setBarFilter('');
        setTagFilter([]);
        setUsernameFilter('');
        await fetchData();
    }

    const handleUsernameClick = async (username) => {
        setTagFilter([]);
        setBarFilter('');
        setUsernameFilter(username);
        await fetchData('', '', username);
    }

    const clearUsernameFilter = async () => {
        console.log('about to clear username filter');
        setBarFilter('');
        setTagFilter([]);
        setUsernameFilter('');
        await fetchData();
    }

    /* example generic useEffect
    useEffect(() => {
        async function fetchData() {
          // You can await here
          const response = await MyAPI.getData(someId);
          // ...
        }
        fetchData();
      }, [someId]); // Or [] if effect doesn't need props or state
    */


    function renderTags(tags) {
        // Split text into individual words and count length
        // const words = tags.split(' ');
        // const count = words.length;

        // //console.log("words", words);

        // // Prepare elements and position tracker
        const elements = [];
        // let position = 0;
        //<Text style={{ marginRight: 20, fontSize: 12, color: '#674FE0' }} onPress={() => handleBarNameClick(item.data.tags)}>{item.data.tags}</Text> :

        tags.map(tag => elements.push((<Text key={tag} style={{ marginRight: 10, fontSize: 12, color: '#674FE0' }} onPress={() => handleTagClick(tag)}>{tag}</Text>)));

        // Loop through words whilst position is less than count
        // while (position < count) {
        //   // Prepare text for specified length and increment position
        //   const text = words.slice(position, length).join(' ');
        //   position += length;

        //   // Append text element
        //   elements.push((
        //     <Text>{text}</Text>
        //   ));
        // }

        return elements;
    }

    function renderTagFilter() {
       
        const elements = [];

        console.log('tagFilter', tagFilter);

        tagFilter.map(tag => elements.push((<Chip
            label={tag}
            key={tag}
            icon={<Icon name="close" style={{ color: 'white', paddingLeft: 5 }} />}
            //color="white"
            //backgroundColor="#674FE0"
            variant="outlined"
            onClick={() => clearTagFilter()}
            //deleteIcon={<Icon name="close" style={{ color: 'white' }} />}
            style={styles.filter}
        />)));

        return elements;
    }

    const handleSoftDeleteClick = async (item) => {
        console.log('handleSoftDeleteClick', item);

        const postRef = doc(db, "Posts", item.id);
        await updateDoc(postRef, {
            status: ['softdeleted']
        });

        fetchData();
    }

    const openProfileModal = async (profileUserId, profileUsername, profileImage) => {
        //setProfileUserId(profileUserId);
        setProfileUserInfo({ userId: profileUserId, username: profileUsername, storageImageUrl: profileImage });
        setIsProfileModalVisible(true);
    }

    const closeProfileModal = async () => {
        setIsProfileModalVisible(false);
    }

    function trimBarName(barName) {
        const windowWidth = Dimensions.get('window').width;
        let maxLength = windowWidth >= 350 ? 25 : 15;
        maxLength = windowWidth > 500 ? 30 : maxLength;

        if (barName.length > maxLength) {
            return barName.substring(0, maxLength) + "...";
        }
        else {
            return barName;
        }
    }

    const showDistanceFilter = async () => {
        setIsDistanceFilterModalVisible(true);
    }

    const closeDistanceFilter = async (newSearchDistanceGeohashLength) => {
        setIsDistanceFilterModalVisible(false);
        console.log("newSearchDistanceGeohashLength", newSearchDistanceGeohashLength);
        if (newSearchDistanceGeohashLength == -1) {
            return
        }        
        if (newSearchDistanceGeohashLength == searchDistanceGeohashLength){
            return;
        }

        setSearchDistanceGeohashLength(newSearchDistanceGeohashLength);
        await fetchData(barFilter, tagFilter, usernameFilter, newSearchDistanceGeohashLength, true);
    }

    function renderDistanceLabel(newLength) {
        if (newLength == 6) {
            return 'distance: 2 miles';
        }
        else if (newLength == 5) {
            return 'distance: 5 miles';
        }
        else if (newLength == 4) {
            return 'distance: 20 miles';
        }
        else if (newLength == 2) {
            return 'distance: 1000 miles';
        }
        else if (newLength == 0) {
            return 'distance: any';
        }
        else {
            return 'distance: 20 miles';
        }
    }

    const showSearchFilter = async () => {
        setIsSearchFilterModalVisible(true);
    }

    const closeSearchFilter = async (newBarNameSearch, newTagSearch, newUsernameSearch) => {

        console.log("newBarNameSearch", newBarNameSearch);
        console.log("newTagSearch", newTagSearch);
        console.log("newUsernameSearch", newUsernameSearch);

        setIsSearchFilterModalVisible(false);
        if (newBarNameSearch && newBarNameSearch.trim().length > 0)
        {
            handleBarNameClick(newBarNameSearch);
        }
        else if (newTagSearch && newTagSearch.trim().length > 0)
        {
            handleTagClick(newTagSearch);
        }
        else if (newUsernameSearch && newUsernameSearch.trim().length > 0)
        {
            handleUsernameClick(newUsernameSearch);
        }
    }

    // viewabilityConfigCallbackPairs is kinda hacky and wierd but works.  otherwise some error about can't change viewable items.
    const onViewableItemsChanged = ({
        viewableItems,
    }) => {
        console.log("Visible items are", viewableItems);
        postsInView = viewableItems;
        const locationToUse = locationTemp ? locationTemp : location;
        setLocationHeaderButton(locationToUse, postsInView);
    };
    const viewabilityConfigCallbackPairs = useRef([
        { onViewableItemsChanged },
    ]);

    const renderHeader = () => {
        return <>
            <View style={{ flexDirection: 'row', paddingTop: 5, paddingBottom: 5, backgroundColor: '#fff' }}>
                <Pressable onPress={() => showSearchFilter()} style={{ paddingLeft: 8, paddingRight: 15 }}>
                    <FontAwesome name="search" size={28} color="#674FE0" style={styles.buttonIcon} />
                </Pressable>
                <Chip
                    label={renderDistanceLabel(searchDistanceGeohashLength)}
                    //key='distance'
                    //color="white"
                    //backgroundColor="#674FE0"
                    variant="outlined"
                    onClick={() => showDistanceFilter()}
                    style={styles.filter}
                //leading={props => <Icon name="close" style={{ color: 'white' }} {...props} />}
                />
                 {barFilter ? <Chip
                    label={trimBarName(barFilter)}
                    icon={<Icon name="close" style={{ color: 'white', paddingLeft: 5 }} />}
                    //color="white"
                    //backgroundColor="#674FE0"
                    variant="outlined"
                    onClick={() => clearBarFilter()}
                    style={styles.filter}
                    //leading={props => <Icon name="close" style={{color: 'white'}} {...props}/>}
                /> : <></>}
                {tagFilter && tagFilter.length ? renderTagFilter() : <></>}
                {usernameFilter ? <Chip
                    label={trimBarName(usernameFilter)}
                    icon={<Icon name="close" style={{ color: 'white', paddingLeft: 5 }} />}
                    //color="white"
                    //backgroundColor="#674FE0"
                    variant="outlined"
                    onClick={() => clearUsernameFilter()}
                    style={styles.filter}
                    //leading={props => <Icon name="close" style={{color: 'white'}} {...props}/>}
                /> : <></>}
            </View>           
        </>;
    };

    const renderEmptyList = () => {
        return (
            <View style={{ flex: 1, flexDirection: 'column', alignContent: 'center', alignItems: 'center', marginTop: 50 }}>
                <Text style={{ fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}>No posts found.</Text>
                {barFilter && barFilter.length > 0 &&
                    <Text style={{ fontSize: 16, textAlign: 'center' }}>No matches for "{barFilter}"</Text>
                }
                {tagFilter && tagFilter.length > 0 &&
                    <Text style={{ fontSize: 16, textAlign: 'center' }}>No matches for "{tagFilter}"</Text>
                }
                {barFilter.length == 0 && tagFilter.length == 0 &&
                    <Text style={{ fontSize: 16, textAlign: 'center' }}>Try adjusting the distance filter</Text>
                }               
            </View>
        );
    }

    const renderItem = useCallback(({ item }) => (
        <View style={{ padding: 5, backgroundColor: '#fff', marginBottom: 8}}>
            <View>
                <View style={{ flexDirection: 'row', marginBottom: 15 }}>
                    {item.data.userInfo && item.data.userInfo.storageImageUrl &&
                        <TouchableOpacity onPress={() => openProfileModal(item.data.userId, item.data.username, item.data.userInfo.storageImageUrl)}>
                            <Image source={{ uri: item.data.userInfo.storageImageUrl }} style={styles.userImage} />
                        </TouchableOpacity>}

                    <View style={{ flex: 1, flexDirection: 'column' }}>
                        <View style={{ flex: 1, flexDirection: 'row', paddingRight: 10 }}>
                            {item.data.username && item.data.username.length > 0 ?
                                <Text style={{ fontSize: 16 }}>{item.data.username}</Text> :
                                <Text style={{ fontSize: 16 }}></Text>}

                            <View style={{ flex: 1, flexDirection: 'row', textAlign: 'right', color: '#ccc', marginRight: 20, alignContent: 'center', alignItems: 'center' }}>
                                {item.data.bar && item.data.bar.length > 0 ?
                                    <><Text style={{ fontSize: 10, marginLeft: 4, marginRight: 4 }}>-</Text><Text style={{ fontSize: 16, color: '#674FE0' }} onPress={() => handleBarNameClick(item.data.bar)}>{trimBarName(item.data.bar)}</Text></> :
                                    <Text style={{ fontSize: 16 }}></Text>}
                            </View>

                            <Menu>
                                <MenuTrigger>
                                    <FontAwesome
                                        name="ellipsis-v"
                                        size={20}
                                        color="#674FE0"
                                        style={styles.buttonIcon}
                                    />
                                </MenuTrigger>
                                <MenuOptions customStyles={{optionWrapper: { padding: 5, backgroundColor: '#E0CD67'}, optionText: { fontWeight: '500', color: '#674FE0' }}}>
                                {userId == item.data.userId &&
                                    <MenuOption onSelect={() => navigation.navigate('PostScreen', { data: item, location: location })} text='Edit' />
                                }
                                {userId != item.data.userId &&
                                    <MenuOption onSelect={() => console.log(`Not called`)} text='Report this post' />
                                }
                                {userRole == "admin" &&
                                    <MenuOption onSelect={() => handleSoftDeleteClick(item)} text='Admin hide post (soft delete)' />
                                }
                                </MenuOptions>
                            </Menu>

                        </View>
                        <View style={{ flex: 1, flexDirection: 'row' }}>

                            <Text style={{ color: '#ccc', marginRight: 10, flex: 1, flexDirection: 'row', textAlign: 'left',  }}>
                                {moment(item.data.created.toDate()).format("h A")} {moment(item.data.created.toDate()).format('dddd')} {moment(item.data.created.toDate()).fromNow()}
                            </Text>
                            <Text style={{ color: '#ccc', marginRight: 5 }}>
                                {getDistanceFromLatLonInMiles(location?.coords.latitude, location?.coords.longitude, item.data.latitude, item.data.longitude)} miles
                            </Text>
                            
                        </View>
                    </View>                    
                </View>
                <View style={{ flexDirection: 'row' }}>
                    {item.data.storageImageUrl && item.data.storageImageUrl.length > 0 && item.data.storageImageUrl2 && item.data.storageImageUrl2.length > 0 
                    && item.data.storageImageUrl3 && item.data.storageImageUrl3.length > 0  &&
                        <View style={{ flexDirection: 'row', width: '100%', height: undefined, aspectRatio: 3 / 2.1 }}>
                            <Swiper
                                controlsProps={{
                                    dotsPos: 'bottom',
                                    prevPos: false, /* hide prev button */
                                    nextPos: false, /* hide next button */
                                    cellsStyle: {
                                        'bottom': { position: 'absolute', bottom: -15, zIndex: 1000 },
                                        'top-left': { position: 'absolute', top: 0, left: 0 },
                                    },
                                }}
                            >
                                <View style={{ width: '100%', height: undefined, aspectRatio: 3 / 2, paddingBottom: 10 }}>
                                    <TouchableOpacity style={{ flex: 1, flexDirection: 'row', backgroundColor: '#E0CD67' }} onPress={() => navigation.navigate('DetailsScreen', { data: item, location: location })}>
                                        <Image source={{ uri: item.data.storageImageUrl }} style={styles.listimage} />
                                    </TouchableOpacity>
                                </View>
                                <View style={{ width: '100%', height: undefined, aspectRatio: 3 / 2, paddingBottom: 10 }}>
                                    <TouchableOpacity style={{ flex: 1, flexDirection: 'row', backgroundColor: '#E0CD67' }} onPress={() => navigation.navigate('DetailsScreen', { data: item, location: location })}>
                                        <Image source={{ uri: item.data.storageImageUrl2 }} style={styles.listimage} />
                                    </TouchableOpacity>
                                </View>
                                <View style={{ width: '100%', height: undefined, aspectRatio: 3 / 2, paddingBottom: 10 }}>
                                    <TouchableOpacity style={{ flex: 1, flexDirection: 'row', backgroundColor: '#E0CD67' }} onPress={() => navigation.navigate('DetailsScreen', { data: item, location: location })}>
                                        <Image source={{ uri: item.data.storageImageUrl3 }} style={styles.listimage} />
                                    </TouchableOpacity>
                                </View>
                            </Swiper>
                        </View>
                    }
                    {item.data.storageImageUrl && item.data.storageImageUrl.length > 0 && item.data.storageImageUrl2 && item.data.storageImageUrl2.length > 0 && !item.data.storageImageUrl3 &&
                        <View style={{ flexDirection: 'row', width: '100%', height: undefined, aspectRatio: 3/2.1 }}>
                            <Swiper
                                controlsProps={{
                                    dotsPos: 'bottom',
                                    prevPos: false, /* hide prev button */
                                    nextPos: false, /* hide next button */
                                    cellsStyle: {
                                      'bottom': { position: 'absolute', bottom: -15, zIndex: 1000 },
                                      'top-left': { position: 'absolute', top: 0, left: 0 },
                                    },
                                  }}
                            >
                                <View style={{ width: '100%', height: undefined, aspectRatio: 3/2, paddingBottom: 10 }}>
                                    <TouchableOpacity style={{ flex: 1, flexDirection: 'row', backgroundColor: '#E0CD67' }} onPress={() => navigation.navigate('DetailsScreen', { data: item, location: location })}>
                                        <Image source={{ uri: item.data.storageImageUrl }} style={styles.listimage} />
                                    </TouchableOpacity>
                                </View>
                                <View style={{ width: '100%', height: undefined, aspectRatio: 3/2, paddingBottom: 10 }}>
                                    <TouchableOpacity style={{ flex: 1, flexDirection: 'row', backgroundColor: '#E0CD67' }} onPress={() => navigation.navigate('DetailsScreen', { data: item, location: location })}>
                                        <Image source={{ uri: item.data.storageImageUrl2 }} style={styles.listimage} />
                                    </TouchableOpacity>
                                </View>
                            </Swiper>
                        </View>
                    }
                    {item.data.storageImageUrl.length > 0 && !item.data.storageImageUrl2 ?
                        <TouchableOpacity style={{ flex: 1, flexDirection: 'row', backgroundColor: '#E0CD67' }}  onPress={() => navigation.navigate('DetailsScreen', { data: item, location: location })}>
                            {/* <View style={{ paddingRight: 15, flex: 1, flexDirection: 'row', backgroundColor: 'yellow' }}> */}
                                <Image source={{ uri: item.data.storageImageUrl }} style={styles.listimage} />
                            {/* </View> */}
                        </TouchableOpacity> :
                        <View></View>
                    }
                </View>
                <View style={{ flexDirection: 'row' }}>
                    
                    <View style={{ flex: 1, flexDirection: 'column' }}>
                        <View style={{ flex: 1, flexWrap: 'wrap', flexDirection: 'column' }}>
                            <TouchableOpacity onPress={() => navigation.navigate('DetailsScreen', { data: item, location: location })}>
                                <View style={{ flex: 1, flexWrap: 'wrap', flexDirection: 'row' }}>
                                    <Text style={{ flexWrap: 'wrap', fontSize: 16, paddingLeft: 5, paddingTop: 5, paddingRight: 5 }}>{item.data.comment}</Text>
                                </View>
                            </TouchableOpacity>
                            
                        </View>
                        <View style={{ alignSelf: 'flex-start', flexDirection: 'row', paddingLeft: 5, paddingTop: 3 }}>
                            {item.data.tagsArray && item.data.tagsArray.length > 0 ?
                                renderTags(item.data.tagsArray) :
                                <></>}
                            
                            {/* {item.data.tags && item.data.tags.length > 0 ?
                                <Text style={{ marginRight: 20, fontSize: 12, color: '#674FE0' }} onPress={() => handleBarNameClick(item.data.tags)}>{item.data.tags}</Text> :
                                <Text style={{ marginRight: 20, fontSize: 12 }}></Text>} */}
                        </View>
                        <View style={{ alignSelf: 'flex-start' }}>
                            <View style={{ marginTop: 10, flexDirection: 'row' }}>
                                <View style={{ marginLeft: 5, width: 110 }}>
                                    <Menu onSelect={value => handleReaction(item.id, value, posts)}>
                                        {!item.data.reactions || item.data.reactions.length === 0 ?
                                            <MenuTrigger>
                                                <FontAwesome
                                                    name="beer"
                                                    size={20}
                                                    color="#25292e"
                                                    style={styles.buttonIcon}
                                                />
                                            </MenuTrigger> :
                                            <MenuTrigger>
                                                <View style={{ marginLeft: 10, width: 100, flexDirection: 'row' }}>
                                                    {item.data.reactions && item.data.reactions.filter(x => x.reactionName == 'beer').length > 0 &&
                                                        <Text>{item.data.reactions.filter(x => x.reactionName == 'beer').length} <FontAwesome
                                                            name="beer"
                                                            size={20}
                                                            color="#EBBB40"
                                                            style={styles.buttonIcon}
                                                        /></Text>
                                                    }
                                                    {item.data.reactions && item.data.reactions.filter(x => x.reactionName == 'heart').length > 0 &&
                                                        <Text>{item.data.reactions.filter(x => x.reactionName == 'heart').length} <FontAwesome
                                                            name="heart"
                                                            size={20}
                                                            color="red"
                                                            style={styles.buttonIcon}
                                                        /></Text>
                                                    }
                                                    {item.data.reactions && item.data.reactions.filter(x => x.reactionName == 'thumbsUp').length > 0 &&
                                                        <Text>{item.data.reactions.filter(x => x.reactionName == 'thumbsUp').length} <FontAwesome
                                                            name="thumbs-up"
                                                            size={20}
                                                            color="blue"
                                                            style={styles.buttonIcon}
                                                        /></Text>
                                                    }
                                                    {item.data.reactions && item.data.reactions.filter(x => x.reactionName == 'thumbsDown').length > 0 &&
                                                        <Text>{item.data.reactions.filter(x => x.reactionName == 'thumbsDown').length} <FontAwesome
                                                            name="thumbs-down"
                                                            size={20}
                                                            color="black"
                                                            style={styles.buttonIcon}
                                                        /></Text>
                                                    }
                                                </View>
                                            </MenuTrigger>}
                                        <MenuOptions>
                                            <MenuOption value={1} style={{ width: 40 }}>
                                                <Text><FontAwesome
                                                    name="beer"
                                                    size={20}
                                                    color="#EBBB40"
                                                    style={styles.buttonIcon}
                                                /></Text>
                                            </MenuOption>
                                            <MenuOption value={2} style={{ width: 40 }}>
                                                <Text><FontAwesome
                                                    name="heart"
                                                    size={20}
                                                    color="red"
                                                    style={styles.buttonIcon}
                                                /></Text>
                                            </MenuOption>
                                            <MenuOption value={3} style={{ width: 40 }}>
                                                <Text><FontAwesome
                                                    name="thumbs-up"
                                                    size={20}
                                                    color="blue"
                                                    style={styles.buttonIcon}
                                                /></Text>
                                            </MenuOption>
                                            <MenuOption value={4} style={{ width: 40 }}>
                                                <Text><FontAwesome
                                                    name="thumbs-down"
                                                    size={20}
                                                    color="black"
                                                    style={styles.buttonIcon}
                                                /></Text>
                                            </MenuOption>
                                        </MenuOptions>
                                    </Menu>
                                </View>
                                <View style={{ width: 95, marginLeft: 5, alignSelf: 'flex-end' }}>
                                    <TouchableOpacity onPress={() => navigation.navigate('DetailsScreen', { data: item, location: location })}>
                                        {item.data.commentCount && item.data.commentCount == 1 &&
                                            <Text style={{ color: '#696969', textAlign: 'right' }}>{item.data.commentCount} comment</Text>
                                        }
                                        {item.data.commentCount && item.data.commentCount > 1 &&
                                            <Text style={{ color: '#696969', textAlign: 'right' }}>{item.data.commentCount} comments</Text>
                                        }
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
                {/* <View style={{ flexDirection: 'row' }}>
                {item.data.tags && item.data.tags.length > 0 ?
                                    <Text style={{ marginRight: 20, fontSize: 12, color: '#674FE0' }} onPress={() => handleBarNameClick(item.data.tags)}>{item.data.tags}</Text> :
                                    <Text style={{ marginRight: 20, fontSize: 12 }}></Text>}
                </View> */}
            </View>
        </View>
    ), [location, posts, styles]);

    return (
        <SafeAreaView style={{ flex: 1 }}>
            <View style={{ flex: 1 }}>
                {/* {!isLoading && isUsingDefaultLocation ? <View style={{ flexDirection: 'row' }}><Text>Using default location: Minneapolis.</Text>
                <Text style={{ color: 'blue' }} onPress={async () => await setDeviceLocation()}>enable location</Text></View> : <></>} */}
                
                {showSetLocationPermission ? 
                <View style={{ flexDirection: 'row', paddingLeft: 5, paddingBottom: 9, backgroundColor: '#CAAA31' }}>
                    <Text style={{marginRight: 3}}>For closest results</Text>
                    <Text style={{color: 'blue' }} onPress={async () => await setDeviceLocation(true)}>enable location</Text>
                </View> 
                : <></>}
                
                {isLoading ? <ActivityIndicator style={{ position: 'absolute', bottom: '50%', right: '50%',}} size="large" color="#ffd33d" animating={isLoading} hidesWhenStopped={true} /> :
                    (
                        <View style={{ flex: 1, flexDirection: 'column', justifyContent: 'space-between' }}>
                            <FlatList
                                data={posts}
                                extraData={extraData}
                                keyExtractor={({ id }, index) => id}
                                renderItem={renderItem}
                                onEndReached={() => fetchMoreData()}
                                refreshControl={
                                    <RefreshControl refreshing={isRefreshing} onRefresh={fetchData} />
                                }
                                ListHeaderComponent={renderHeader}
                                ListEmptyComponent={renderEmptyList}
                                removeClippedSubviews={false}
                                viewabilityConfigCallbackPairs={
                                    viewabilityConfigCallbackPairs.current
                                  }
                            />
                        </View>
                    )}

                {isLoading ? <></> :
                    (<FAB
                        variant="standard"
                        icon={props => <Icon name="plus" {...props} />}
                        //label=""
                        color="#674FE0"
                        style={styles.fab}
                        onPress={() => navigation.navigate('PostScreen', { location: location })}
                      />)}
            </View>
            {/* <View style={{ alignItems: 'center', justifyContent: 'center', padding: 10 }}>
                <Button theme="primary" label="Add a post" onPress={() => navigation.navigate('PostScreen')} />
            </View> 
            <MenuOption onSelect={() => navigation.navigate('PostScreen', { data: item })} text='Edit' />
            */}
            <ProfileModal
                message={""}
                visible={isProfileModalVisible}
                dismiss={closeProfileModal}
                userInfo={profileUserInfo}
            ></ProfileModal>
            <DistanceFilterModal
                message={""}
                visible={isDistanceFilterModalVisible}
                dismiss={closeDistanceFilter}
                userInfo={''}
            ></DistanceFilterModal>
            <SearchFilterModal
                message={""}
                visible={isSearchFilterModalVisible}
                dismiss={closeSearchFilter}
                barFilter={barFilter}
                tagFilter={tagFilter}
            ></SearchFilterModal>
            <ContactUsModal
                visible={isContactUsModalVisible}
                dismiss={closeContactUsModal}
            ></ContactUsModal>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    listimage: {
        width: '100%',
        height: undefined,
        aspectRatio: 3/2,
        borderRadius: 4,
      //paddingRight: 15,
      //marginBottom: 5
    },
    buttonIcon: {
        // paddingLeft: 5,
    },
    userImage: {
        width: 40,
        height: 40,
        borderRadius: 40/2,
        marginRight: 5,
      },
    fab: {
        position: 'absolute',
        bottom: 16,
        right: 16,
    },
    filter: {
        alignSelf: 'center',
        color: '#fff',
        backgroundColor: '#674FE0',
        //backgroundColor: "#0D4DF2",
    },

    //   const fabStyle = {
    //     position: 'absolute',
    //     bottom: 16,
    //     right: 16,
    //   };

    container: {
        flex: 1,
      },
      slideContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      },
      slide1: {
        backgroundColor: 'rgba(20,20,200,0.3)',
      },
      slide2: {
        backgroundColor: 'rgba(20,200,20,0.3)',
      },
      slide3: {
        backgroundColor: 'rgba(200,20,20,0.3)',
      },

  });
  
