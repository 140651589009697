import { storage } from '../../firebaseConfig';
import { ref, uploadBytes, uploadString, getDownloadURL } from "firebase/storage";
import { generateGuid }  from '../utils/General'

import { getFunctions, httpsCallable } from "firebase/functions";

// import tinify from 'tinify';

export async function saveImage(imageLocalUrl) {
    var storageImageRef = "";
    var storageImageUrl = "";
    
    // TODO not sure how the imageLocalUrl is on Android/iOS native apps
    // pc web = the imageLocalUrl is like so = data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAWQAAAIKCAYAAAAUDTWXAAAAAX...
    // imageType = png,jpeg,etc
    let imageType = imageLocalUrl.substring("data:image/".length, imageLocalUrl.indexOf(";base64"));
    if (imageType != "png" && imageType != "jpg" && imageType != "jpeg") {
        alert("Error code E3:  The image type is " + imageType + " and not the expected png or jpg.  Let Dan know about error code E3 if you want.")
    }
    if (imageType == "jpeg") {
        imageType = "jpg";
    }

    let uuid = generateGuid();

    let storageLocationString = 'images/' + uuid + "." + imageType;
    const storageRef = ref(storage, storageLocationString);

    // Data URL string
    var snapshotRef = null;
    const message4 = imageLocalUrl;
    await uploadString(storageRef, message4, 'data_url').then((snapshot) => {
        snapshotRef = snapshot.ref;
        console.log('Uploaded a data_url string!');
        console.log('snapshotRef=' + snapshotRef);
    });

    console.log('here snapshotRef=' + snapshotRef);
    if (snapshotRef != null) {
        console.log('here');
        await getDownloadURL(snapshotRef).then((url) => {
            storageImageRef = snapshotRef.toString();
            storageImageUrl = url;
            console.log('File available at', url);
        });
    }

    //gs://atabar-d7693.appspot.com/images/a1735e0b-91ec-4e82-afcf-fe1331367191.jpg
    const functions = getFunctions();
    const compressImage = httpsCallable(functions, 'compressImage2');
    var imageinstorage = storageImageRef.replace("gs://atabar-d7693.appspot.com/", "");
    console.log('imageinstorage=' + imageinstorage);
    compressImage({ imageinstorage: imageinstorage })
        .then((result) => {
            // // Read result of the Cloud Function.
            // /** @type {any} */
            // const data = result.data;
            // const sanitizedMessage = data.text;
            console.log('success calling compressImage');
        })
        .catch((error) => {
            console.log('error calling compressImage', error);
            // Getting the Error details.
            // const code = error.code;
            // const message = error.message;
            // const details = error.details;
            // ...
        });

    return {
        'storageImageRef': storageImageRef,
        'storageImageUrl': storageImageUrl
    };
}

/*
export async function saveImageWithTinyPNG(imageLocalUrl) {
    tinify.key = "wgPT7zZphsvJDQVlL8BmXGfSY4kYMDcc";

    const sourceData = base64ToBinaryString(base64Image);

    tinify.fromBuffer(sourceData).toBuffer(function(err, resultData) {
        if (err) throw err;
        console.log('resultData=' + resultData);
      });
}


function base64ToBinaryString(base64Image) {
    // Remove the data URI prefix if it exists
    const base64Data = base64Image.replace(/^data:image\/\w+;base64,/, '');
  
    // Decode the base64 data into a binary string
    const binaryString = atob(base64Data);
  
    return binaryString;
  }
  */