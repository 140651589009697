import Modal from "react-native-modal";
import { StyleSheet, View, Button, Text, Image } from 'react-native'; //Modal
import FontAwesome from "@expo/vector-icons/FontAwesome";
import { useState, useEffect } from 'react';
import Chip from '@mui/material/Chip';
// import { db } from '../firebaseConfig';
// import { doc, getDoc } from 'firebase/firestore';

// TODO: perhaps delete this component and use the one from react-native-modal
export default function DistanceFilterModal({ message, visible, dismiss, userInfo }) {

  const [username2, setUsername2] = useState('');
  const [selectedImage2, setSelectedImage2] = useState(null);

  useEffect(() => {
    (async () => {
      // const userRef2 = doc(db, "Users", userId);
      // const userSnap2 = await getDoc(userRef2);
      if (userInfo) {
        if (userInfo.username) {
          setUsername2(userInfo.username);
          console.log('userInfo.username: ', userInfo.username);
        }
        if (userInfo.storageImageUrl) {
          setSelectedImage2(userInfo.storageImageUrl);
        }
      }
    })();
  }, [userInfo])

  return (
    <Modal
      isVisible={visible}
      hasBackdrop={true}
      backdropColor="#EBBB40"
      backdropOpacity={0.6}
      onBackdropPress={() => dismiss(-1)}
      //style={{ width: 200, minHeight: 200 }}
      // transparent={transparent}
      // onRequestClose={dismiss}
    >
      {/* <TouchableWithoutFeedback onPress={dismiss}>
        <View style={styles.modalOverlay} />
      </TouchableWithoutFeedback> */}

      <View style={{ width: 250, height: 280, backgroundColor: 'white', alignSelf: 'center' }}>
        <View style={{ flex: 1, flexDirection: 'column', alignContent: 'center' }}>

          <Text style={{ fontSize: 18 }}>Choose a search distance:</Text>

          <Chip
            label={'2 miles'}
            variant="outlined"
            onClick={() => dismiss(6)}
            style={styles.filter}
          />

          <Chip
            label={'5 miles'}
            variant="outlined"
            onClick={() => dismiss(5)}
            style={styles.filter}
          />

          <Chip
            label={'20 miles'}
            variant="outlined"
            onClick={() => dismiss(4)}
            style={styles.filter}
          />

          <Chip
            label={'1000 miles'}
            variant="outlined"
            onClick={() => dismiss(2)}
            style={styles.filter}
          />

          <Chip
            label={'any'}
            variant="outlined"
            onClick={() => dismiss(0)}
            style={styles.filter}
          />

        </View>
      </View>
    </Modal>
  );
}

 
const styles = StyleSheet.create({ 
  buttonLabel: {
    color: '#fff',
    fontSize: 16,
  },
  userImage2: {
    width: '100%',
    height: 200,
    //borderRadius: 40/2,
    //marginRight: 5,
  },
  filter: {
    alignSelf: 'center',
    color: '#fff',
    backgroundColor: "#0D4DF2",
    marginTop: 10,
    marginBottom: 10,
  }
  // modalBackdrop: {
  //   position: fixed;
  //   top: 0;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  //   background-color: rgba(0, 0, 0, 0.4);
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   z-index: 2;
  // }
  
  // .modal-content {
  //   width: 60%;
  //   min-height: 200px;
  //   background-color: white;
  //   padding: 25px;
  // }
  
});
