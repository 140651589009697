import { StatusBar } from 'expo-status-bar';
import { StyleSheet, View, Image, Pressable } from 'react-native';
import { useState } from 'react';
import * as ImagePicker from 'expo-image-picker';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import FontAwesome from "@expo/vector-icons/FontAwesome";
import Ionicons from '@expo/vector-icons/Ionicons';

import Button from './components/Button';
import ImageViewer from './components/ImageViewer';
const LogoImage = require('./assets/images/logo.png');
const Stack = createNativeStackNavigator();
import { MenuProvider } from 'react-native-popup-menu';

import HomeScreen from './components/HomeScreen';
import PostScreen from './components/PostScreen';
import DetailsScreen from './components/DetailsScreen';
import CreateAccountScreen from './components/CreateAccountScreen';
import CommentScreen from './components/CommentScreen';
import UserSummaryScreen from './components/UserSummaryScreen';
import LocationScreen from './components/LocationScreen';

export default function App({ navigation }) {
  // const [selectedImage, setSelectedImage] = useState(null);

  // const pickImageAsync = async () => {
  //   let result = await ImagePicker.launchImageLibraryAsync({
  //     allowsEditing: true,
  //     quality: 1,
  //   });

  //   if (!result.canceled) {
  //     setSelectedImage(result.assets[0].uri);
  //   } else {
  //     alert('You did not select any image.');
  //   }
  // };

  function LogoTitle() {
    return (
      <Image
        style={{ width: 114, height: 32 }}
        source={LogoImage}
      />
    );
  }

  return (
    <MenuProvider>
      <NavigationContainer>
        <Stack.Navigator initialRouteName="HomeScreen">
          <Stack.Screen name="HomeScreen"
            component={HomeScreen}
            options={({ navigation }) => ({
              //title: '',
              //hea
              //headerBackImageSource: <Ionicons name="location-sharp" size={30} color="#25292e" style={styles.buttonIconLeft} />,
              headerTitle: props => <LogoTitle {...props} />,
              headerStyle: {
                backgroundColor: '#CAAA31',
                
                //height: 50,  //only works on web i think
              },
              headerShadowVisible: false,
              headerTitleAlign: 'center',
              headerTintColor: '#fff',
              headerTitleStyle: {
                fontWeight: 'bold',
                color: '#674FE0',
              },
              // headerLeft: () => (
              //   <Pressable
              //     // style={[styles.button, { backgroundColor: "#fff" }]}
              //     onPress={() => navigation.navigate('LocationScreen', { location: location })}
              //   >
              //     <Ionicons name="location-sharp" size={30} color="#25292e" style={styles.buttonIconLeft} />
              //   </Pressable>
              // ),
              // Add a placeholder button without the `onPress` to avoid flicker
              headerRight: () => (
                <Pressable
                  // style={[styles.button, { backgroundColor: "#fff" }]}
                  onPress={() => navigation.navigate('CreateAccountScreen')}
                >
                  <FontAwesome
                    name="user"
                    size={30}
                    color="#25292e"
                    style={styles.buttonIcon}
                  />
                </Pressable>
              ),
            })}
          />
          <Stack.Screen name="PostScreen" component={PostScreen} options={{ title: 'Create a post' }} />
          <Stack.Screen name="DetailsScreen" 
            component={DetailsScreen} 
            options={{ 
              title: '',
              headerStyle: {
                backgroundColor: '#E0CD67',
              }, 
            }} />
          <Stack.Screen name="CreateAccountScreen"
            component={CreateAccountScreen}
            options={{
              title: 'Edit Profile',
              headerStyle: {
                backgroundColor: '#E0CD67',
              },
            }} />
          <Stack.Screen name="CommentScreen" component={CommentScreen} options={{ title: 'Add comment' }} />
          <Stack.Screen name="UserSummaryScreen"
            component={UserSummaryScreen}
            options={{
              title: 'Information',
            }} />
            <Stack.Screen name="LocationScreen"
            component={LocationScreen}
            options={{
              title: 'Your location',
              headerStyle: {
                backgroundColor: '#E0CD67',
                //height: 50,  //only works on web i think
              },
              //headerTitleAlign: 'center',
              headerTintColor: 'black',
              // headerTitleStyle: {
              //   fontWeight: 'bold',
              //   color: '#674FE0',
              // },
            }} />
        </Stack.Navigator>
      </NavigationContainer>
    </MenuProvider>
  );
}

const styles = StyleSheet.create({
  buttonIconLeft: {
    paddingLeft: 15,
  },
  buttonIcon: {
    paddingRight: 15,
  }
});


