import Modal from "react-native-modal";
import { StyleSheet, View, Button, Text, Image, TextInput } from 'react-native'; //Modal
import FontAwesome from "@expo/vector-icons/FontAwesome";
import { useState, useEffect } from 'react';
import Chip from '@mui/material/Chip';
// import { db } from '../firebaseConfig';
// import { doc, getDoc } from 'firebase/firestore';


import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Configure, SearchBox, Hits, RefinementList, CurrentRefinements } from 'react-instantsearch';

// TODO: perhaps delete this component and use the one from react-native-modal
export default function SearchFilterModal({ message, visible, dismiss, barFilter, tagFilter }) {

  const [barNameSearch, setBarNameSearch] = useState('');
  const [tagSearch, setTagSearch] = useState('');
  const searchClient = algoliasearch('V7ZIR4P3MM', '0c9a6ffb3ff067bd310d7a7d172b82d3');

  useEffect(() => {
    (async () => {
      if(barFilter) {
        setBarNameSearch(barFilter);
      }
      else
      {
        setBarNameSearch('');
      }
      if(tagFilter) {
        setTagSearch(tagFilter);
      }
      else
      {
        setTagSearch('');
      }
    })();
  }, [barFilter, tagFilter])

  const setBarNameSearchFunc = async (newValue) => {
    setBarNameSearch(newValue);
    setTagSearch('');
  }

  const setTagSearchFunc = async (newValue) => {
    setBarNameSearch('');
    setTagSearch(newValue);
  }

  function Hit({ hit }) {
    console.log("hit:", hit);
    return (
      <View>
        {hit.bar && <View style={{ flexDirection: 'row'}}><Text style={{ fontSize: 12 }}>bar: </Text><Text style={{ fontSize: 14, color: "#674FE0" }} onClick={() => dismiss(hit.bar, '', '')}>{hit.bar}</Text></View>}
        {hit.username && <View style={{ flexDirection: 'row'}}><Text style={{ fontSize: 12 }}>username: </Text><Text style={{ fontSize: 14, color: "#674FE0" }} onClick={() => dismiss('', '', hit.username)}>{hit.username}</Text></View>}
        {hit.tagsArray && hit.tagsArray != null && hit.tagsArray.length > 0 && 
          hit.tagsArray.map(tag => <View style={{ flexDirection: 'row'}} key={tag + 'view'}><Text style={{ fontSize: 12 }} key={tag + 'label'}>tag: </Text><Text key={tag} style={{ fontSize: 14, color: "#674FE0" }} onClick={() => dismiss('', tag, '')}>{tag}</Text></View>)
        }
         {/* <Text style={{ fontSize: 14 }}>Search by bar name, tag, or username:</Text>
        <p onClick={() => {console.log('clicked this bar', hit.bar)}}>{hit.bar}</p>
        <p>{hit.username}</p>
        <p>{hit.tagsArray}</p> */}
      </View>
    );
  }

  // const deleteDuplicates = (items) =>
  // items
  //   .map(item => {
  //     // make a temporary key for deduplication
  //     item.__dedupe = `${item.index}.${item.id}`;
  //     return item;
  //   })
  //   .filter(
  //     (obj, pos, arr) =>
  //       arr.map(item => item.__dedupe).indexOf(obj.__dedupe) === pos
  //   )
  //   .map(item => {
  //     // delete it afterwards
  //     delete item.__dedupe;
  //     return item;
  //   });
  
  return (
    <Modal
      isVisible={visible}
      hasBackdrop={true}
      backdropColor="#EBBB40"
      backdropOpacity={0.6}
      onBackdropPress={() => dismiss(0)}
      //style={{ width: 200, minHeight: 200 }}
      // transparent={transparent}
      // onRequestClose={dismiss}
    >
      {/* <TouchableWithoutFeedback onPress={dismiss}>
        <View style={styles.modalOverlay} />
      </TouchableWithoutFeedback> */}

      <View style={{ width: 250, height: 400, padding: 5, backgroundColor: 'white', alignSelf: 'center' }}>
        <View style={{ flex: 1, flexDirection: 'column', alignContent: 'center' }}>

          <Text style={{ fontSize: 14 }}>Search by bar name, tag, or username:</Text>
          {/* <TextInput
            style={{ height: 40, width: '100%', backgroundColor: '#f0f0f0', padding: 5, marginTop: 5, marginBottom: 10 }}
            placeholder="enter bar name"
            placeholderTextColor="gray"
            onChangeText={newText => setBarNameSearchFunc(newText)}
            defaultValue={barNameSearch}
            textAlignVertical={"center"}
            maxLength={40}
          />

          <Text style={{ fontSize: 18, width: '100%', textAlign: 'center', fontWeight: 'bold' }}>or</Text>

          <Text style={{ fontSize: 18 }}>Search by tag:</Text>
          <TextInput
            style={{ height: 40, width: '100%', backgroundColor: '#f0f0f0', padding: 5, marginTop: 5, marginBottom: 20 }}
            placeholder="enter tag"
            placeholderTextColor="gray"
            onChangeText={newText => setTagSearchFunc(newText.toLowerCase())}
            defaultValue={tagSearch}
            textAlignVertical={"center"}
            maxLength={40}
          />

          <Button
            style={{ marginTop: 50 }}
            onPress={() => dismiss(barNameSearch, tagSearch)}
            title="Search"
            color="#0D4DF2"
          /> */}

          <InstantSearch searchClient={searchClient} indexName="barswim_posts">
            <Configure
              hitsPerPage={7}
              distinct={true}
              attributesToRetrieve={[
                "bar",
                "username",
                "tagsArray",
              ]}
              restrictSearchableAttributes={[
                "bar",
                "username",
                "tagsArray",
              ]} />
            <SearchBox placeholder="search posts" />
            <Hits hitComponent={Hit}  />
            {/* <CurrentRefinements transformItems={deleteDuplicates}/> */}
          </InstantSearch>

        </View>
      </View>
    </Modal>
  );
}

 
const styles = StyleSheet.create({ 
  buttonLabel: {
    color: '#fff',
    fontSize: 16,
  },
  userImage2: {
    width: '100%',
    height: 200,
    //borderRadius: 40/2,
    //marginRight: 5,
  },
  filter: {
    alignSelf: 'center',
    color: '#fff',
    backgroundColor: "#0D4DF2",
    marginTop: 30,
  }
  // modalBackdrop: {
  //   position: fixed;
  //   top: 0;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  //   background-color: rgba(0, 0, 0, 0.4);
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   z-index: 2;
  // }
  
  // .modal-content {
  //   width: 60%;
  //   min-height: 200px;
  //   background-color: white;
  //   padding: 25px;
  // }
  
});
