import { StyleSheet, Image } from 'react-native';
import Button from './Button';

export default function ImageViewer({ placeholderImageSource, selectedImage }) {
    
  const imageSource = selectedImage !== null
    ? { uri: selectedImage }
    : placeholderImageSource;

  //console.log("in image viewer:");  
  //console.log(selectedImage);

  return (
    <Image source={imageSource} style={styles.image} />
  );
}

const styles = StyleSheet.create({
  image: {
    flex: 1,
    flexDirection: 'row',
  },
});
