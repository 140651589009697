import { initializeApp } from 'firebase/app';

// Optionally import the services that you want to use
import { getAuth } from "firebase/auth";
// import {...} from "firebase/database";
import { getFirestore } from "firebase/firestore";
// import {...} from "firebase/functions";
import { getStorage } from "firebase/storage";

// Initialize Firebase
const firebaseConfig = {
  apiKey: 'AIzaSyDqIEOOx5ha_0jbVILtX_Lh_IYnmI27bE8',
  authDomain: 'atabar-d7693.firebaseapp.com',
  // TODO? databaseURL: 'https://project-id.firebaseio.com',
  databaseURL: 'https://atabar-d7693.firebaseio.com',
  projectId: 'atabar-d7693',
  storageBucket: 'atabar-d7693.appspot.com',
  messagingSenderId: '208980471205',
  appId: '1:208980471205:web:9e87c109960d00148b0c4c',
  measurementId: 'G-HVGN104YL8',
};

// apiKey: "AIzaSyDqIEOOx5ha_0jbVILtX_Lh_IYnmI27bE8",
//   authDomain: "atabar-d7693.firebaseapp.com",
//   projectId: "atabar-d7693",
//   storageBucket: "atabar-d7693.appspot.com",
//   messagingSenderId: "208980471205",
//   appId: "1:208980471205:web:9e87c109960d00148b0c4c",
//   measurementId: "G-HVGN104YL8"

const app = initializeApp(firebaseConfig);
// For more information on how to access Firebase in your project,
// see the Firebase documentation: https://firebase.google.com/docs/web/setup#access-firebase

//export const firestore = getFirestore();
export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);

// import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
