import Modal from "react-native-modal";
import { StyleSheet, View, Button, Text, Image } from 'react-native'; //Modal
import FontAwesome from "@expo/vector-icons/FontAwesome";
import { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';


// TODO: perhaps delete this component and use the one from react-native-modal
export default function ContactUsModal({ visible, dismiss }) {

//   const [username2, setUsername2] = useState('');

  useEffect(() => {
    (async () => {
      
    })();
  }, [])

  return (
    <Modal
      isVisible={visible}
      hasBackdrop={true}
      backdropColor="#EBBB40"
      backdropOpacity={0.6}
      onBackdropPress={dismiss}
    >
      <View style={{ width: 250, height: 50, backgroundColor: 'white', alignSelf: 'center' }}>
        <View style={{ flex: 1, flexDirection: 'column', alignContent: 'center' }}>
          <Text style={{ fontSize: 16, width: '100%', textAlign: 'center' }}>Email barswim at barswim@gmail.com</Text>
        </View>
      </View>
    </Modal>
  );
}

 
const styles = StyleSheet.create({ 
//   buttonLabel: {
//     color: '#fff',
//     fontSize: 16,
//   },
});
