import { db } from '../../firebaseConfig';
import { doc, collection, setDoc, addDoc, Timestamp, updateDoc, query, where, getDoc, getDocs } from "firebase/firestore"; 


// Not used yet
export async function getUserDocIdByUsername(username) {

    const q = query(collection(db, "Users"), where('userNameLowerCase', "==", username.toLowerCase()));

    const querySnapshot = await getDocs(q);
    var userIdWithUsername = null;
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        //console.log(doc.id, " => ", doc.data());
        userIdWithUserName = doc.id;
    });
    return userIdWithUsername;
}

export async function getUserDataByUserId(userId) {
    const userRef = doc(db, "Users", userId);
    const userSnap = await getDoc(userRef);

    if (!userSnap.exists()) {
        console.log('No such document!');
        return null;
    }

    // adding some null checks - this feels hacky though, shouldn't be needed, means something is wrong
    return {
        username: userSnap.data().username ? userSnap.data().username : '',
        storageImageUrl: userSnap.data().storageImageUrl ? userSnap.data().storageImageUrl : '',
        role: userSnap.data().role ? userSnap.data().role : '',
        emailLowerCase: userSnap.data().emailLowerCase ? userSnap.data().emailLowerCase : '',
    }
}

export async function getUserIdByEmail(email) {
    const q = query(collection(db, "Users"), where('emailLowerCase', "==", email.toLowerCase()));

    const querySnapshot = await getDocs(q);
    var userId = null;
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        //console.log(doc.id, " => ", doc.data());
        userId = doc.id;
    });
    return userId;
}

export async function updateUserEmailToConfirmed(userId, email) {

    const userRef = doc(db, "Users", userId);

    await updateDoc(userRef, {
        email: email,
        isEmailConfirmed: true
    });

    console.log('updated user email to confirmed - in Users');
}