import { StatusBar } from 'expo-status-bar';
import { StyleSheet, View, Image, TextInput, TouchableWithoutFeedback, ActivityIndicator, SafeAreaView, ScrollView, Pressable, Alert, Text } from 'react-native';
import { useState, useEffect, useRef } from 'react';
import * as ImagePicker from 'expo-image-picker';
import { doc, collection, setDoc, addDoc, Timestamp, updateDoc } from "firebase/firestore";
import { FAB, Chip, IconButton } from "@react-native-material/core";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import {GooglePlacesAutocomplete} from 'react-native-google-places-autocomplete';
// import { ref, uploadBytes, uploadString, getDownloadURL } from "firebase/storage";
import * as Location from 'expo-location';
import Ionicons from '@expo/vector-icons/Ionicons';

import { db } from '../firebaseConfig';
//import { storage } from '../firebaseConfig';
const geofire = require('geofire-common');

import Button from '../components/Button';
import ConfirmModal from '../components/ConfirmModal'
import ImageViewer from '../components/ImageViewer';
import { getLocation }  from '../services/utils/General'
import { saveImage }  from '../services/storage/Storage'
import { getUserDataByUserId }  from '../services/users/Users';

const PlaceholderImage = require('../assets/images/selectimage.png');


/* todo decide if we want to do 
class Post {
  constructor (comment) {
      this.comment = comment;
      // pass in more params when ready
      //this.state = state;
      //this.country = country;
  }
  toString() {
      return this.comment;// + ', ' + this.state + ', ' + this.country;
  }
}

// Firestore data converter
const postConverter = {
  toFirestore: (post) => {
      return {
          name: post.comment//,
          // state: post.state,
          // country: post.country
          //created: Timestamp.now()
          };
  },
  fromFirestore: (snapshot, options) => {
      const data = snapshot.data(options);
      return new Post(data.comment); //, data.state, data.country);
  }
};
*/


export default function PostScreen({ route, navigation }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImage2, setSelectedImage2] = useState(null);
  const [selectedImage3, setSelectedImage3] = useState(null);
  const [text, setText] = useState('');
  const [bar, setBar] = useState('');
  const [tags, setTags] = useState([]);
  const [tag, setTag] = useState('');
  const [animating, setAnimating] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [postId, setPostId] = useState(false);
  const [isNewImage, setIsNewImage] = useState(false);
  const [isNewImage2, setIsNewImage2] = useState(false);
  const [isNewImage3, setIsNewImage3] = useState(false);
  const [location, setLocation] = useState(null);
  //const { data } = route.params ?? null;
  const ref = useRef(null);
  const refBar = useRef(null);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] = useState(false);
  const [showChooseMorePhotos, setShowChooseMorePhotos] = useState(false);
  const [googlePlacesDetails, setGooglePlacesDetails] = useState(null);

  useEffect(() => {
    (async () => {

      let savedUserId = window.localStorage.getItem('userId');
      if (!savedUserId) {
        alert("Sign in to create a post");
        navigation.navigate('CreateAccountScreen');
      }

      //alert("username:" + window.localStorage.getItem('username'));

      //console.log("route.params.data", route.params.data);

      if (route != null && route.params != null && route.params.data != null && route.params.data.data.userId == savedUserId) {
        var data = route.params.data.data;
        setIsUpdating(true);
        setPostId(route.params.data.id);
        navigation.setOptions({ title: 'Edit post' });
        // this is "late", setIsUpdating sets the value ...console.log("is editing", isUpdating);
        if (data.storageImageUrl) {
          setSelectedImage(data.storageImageUrl);
        }
        if (data.storageImageUrl2) {
          setSelectedImage2(data.storageImageUrl2);
          setShowChooseMorePhotos(true);
        }
        if (data.storageImageUrl3) {
          setSelectedImage3(data.storageImageUrl3);
          setShowChooseMorePhotos(true);
        }
        setText(data.comment);
        //setBar(data.bar);
        refBar.current.setAddressText(data.bar);
        setTags(data.tagsArray);
        setDeleteOption();
      }

      //var locationResult = await getLocation();
      console.log("location:", route.params.location);
      if (route.params.location) {
        setLocation(route.params.location);
      }
      // else
      // {      
      //   const [status, requestPermission] = Location.useForegroundPermissions();
      //   console.log("status:", status);
      //   console.log("requestPermission:", requestPermission);
      // }

    })();
  }, [])

  const deletePost = async () => {
    setIsDeleteConfirmVisible(true);
  }

  const closePopup = async () => {
    setIsDeleteConfirmVisible(false);
  }

  const confirmDeletePost = async () => {

    console.log('confirmDeletePost');

    const postRef = doc(db, "Posts", postId);
    await updateDoc(postRef, {
        status: ['softdeleted']
    });

    setIsDeleteConfirmVisible(false);

    navigation.navigate({
      name: 'HomeScreen',
      params: { shouldRefresh: true },
      merge: true,
    });
  }


  async function setDeleteOption() {
    navigation.setOptions({
      headerRight: () => (
        <Pressable onPress={deletePost} style={{ paddingRight: 15 }}>
          <Ionicons name="trash-outline" size={30} color="#25292e" />
        </Pressable>
      )
    });
  }

  const pickImageAsync = async () => {
    // neither of these setting work for web i dont think
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      quality: .5,
    });

    if (!result.canceled) {
      setSelectedImage(result.assets[0].uri);
      setIsNewImage(true);
      //console.log(result.assets[0].uri);
      //let testing64string = result.assets[0].uri;     
    } else {
      alert('You did not select an image.');
    }
  };
  const pickImage2Async = async () => {
    // neither of these setting work for web i dont think
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      quality: .5,
    });

    if (!result.canceled) {
      setSelectedImage2(result.assets[0].uri);
      setIsNewImage2(true);
      //console.log(result.assets[0].uri);
      //let testing64string = result.assets[0].uri;     
    } else {
      alert('You did not select an image.');
    }
  };
  const pickImage3Async = async () => {
    // neither of these setting work for web i dont think
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      quality: .5,
    });

    if (!result.canceled) {
      setSelectedImage3(result.assets[0].uri);
      setIsNewImage3(true);
      //console.log(result.assets[0].uri);
      //let testing64string = result.assets[0].uri;     
    } else {
      alert('You did not select an image.');
    }
  };

  /* function to add new post to firestore */
  const handleSubmit = async (e) => {

    setIsSubmitDisabled(true);

    console.log('handleSubmit');

    e.preventDefault()

    //console.log('refBar', refBar.current.getAddressText());

    var storageImageRef = "";
    var storageImageUrl = "";
    var storageImageRef2 = "";
    var storageImageUrl2 = "";
    var storageImageRef3 = "";
    var storageImageUrl3 = "";

    console.log('refBar.current', refBar.current);
    
    if (selectedImage == null || selectedImage.length == 0)
    {
      setIsSubmitDisabled(false);
      alert('Add an image');
      return;
    }

    if (text == null || text.length == 0)
    {
      setIsSubmitDisabled(false);
      alert('Type a message');
      return;
    }

    if (refBar.current.getAddressText() == null || refBar.current.getAddressText().length == 0)
    {
      setIsSubmitDisabled(false);
      alert('What bar are you at?');
      return;
    }

    const barName = refBar.current.getAddressText().indexOf(",") > 0 ? 
      refBar.current.getAddressText().substring(0, refBar.current.getAddressText().indexOf(","))
      : refBar.current.getAddressText();
    setBar(barName)

    // show activity indicator
    setAnimating(true);
    
    if (selectedImage != null && isNewImage) {
      var saveImageResult = await saveImage(selectedImage);
      storageImageRef = saveImageResult.storageImageRef;
      storageImageUrl = saveImageResult.storageImageUrl;
    }
    if (selectedImage2 != null && isNewImage2) {
      var saveImageResult2 = await saveImage(selectedImage2);
      storageImageRef2 = saveImageResult2.storageImageRef;
      storageImageUrl2 = saveImageResult2.storageImageUrl;
    }
    if (selectedImage3 != null && isNewImage3) {
      var saveImageResult3 = await saveImage(selectedImage3);
      storageImageRef3 = saveImageResult3.storageImageRef;
      storageImageUrl3 = saveImageResult3.storageImageUrl;
    }

    // moved this to the add tag method so user sees the tag as it will be saved
    // // format tags - remove spaces, remove #, make lowercase
    // for (var i = 0; i < tags.length; i++) {
    //   tags[i] = tags[i].trim().toLowerCase().replace(/\s+/g, '');
    //   if (tags[i].startsWith('#')) {
    //     tags[i] = tags[i].substring(1);
    //   }
    // }

    try {
      // // Set with cityConverter - this does add or update (update if uniqueid already exists - does complete overwrite)
      // const ref = doc(db, "posts", "uniqueid").withConverter(postConverter);
      // await setDoc(ref, new Post(newText));

      console.log("location:", location);

      let lat = location ? location.coords.latitude : null;
      let lng = location ? location.coords.longitude : null;
      let hash = location ? geofire.geohashForLocation([lat, lng]) : null;

      // if a user has selected a place from the google places api, use that location
      if (googlePlacesDetails) {
        console.log('using googlePlacesDetails', googlePlacesDetails);
        lat = googlePlacesDetails.geometry.location.lat;
        lng = googlePlacesDetails.geometry.location.lng;
        hash = geofire.geohashForLocation([lat, lng]);
      }

      if (!isUpdating) {

        // get user image
        var userInfo = await getUserDataByUserId(window.localStorage.getItem('userId'));

        console.log('userInfo', userInfo);

        // Add a new document with a generated id.
        const docRef = await addDoc(collection(db, 'Posts'), {
          comment: text,
          storageImageRef: storageImageRef,
          storageImageUrl: storageImageUrl,
          storageImageRef2: storageImageRef2,
          storageImageUrl2: storageImageUrl2,
          storageImageRef3: storageImageRef3,
          storageImageUrl3: storageImageUrl3,
          username: window.localStorage.getItem('username'),
          userId: window.localStorage.getItem('userId'),
          created: Timestamp.now(),
          latitude: lat,
          longitude: lng,
          geohash: hash,
          geohash2: hash.substring(0, 2),
          geohash3: hash.substring(0, 3),
          geohash4: hash.substring(0, 4),
          geohash5: hash.substring(0, 5),
          geohash6: hash.substring(0, 6),
          userInfo: {
            storageImageUrl: userInfo.storageImageUrl ? userInfo.storageImageUrl : '',
          },
          bar: barName,
          //tags: tags,
          tagsArray: tags,
          status: ['ok'],
        });
        console.log("Document written with ID: ", docRef.id);

      }
      else {
       
        console.log('update the post');

        try {
          const postRef = doc(db, "Posts", postId);

          // update the Post
          if (selectedImage != null && isNewImage)
          {
            await updateDoc(postRef, {
              storageImageRef: storageImageRef,
              storageImageUrl: storageImageUrl,
            });
          }

          if (selectedImage2 != null && isNewImage2) {
            await updateDoc(postRef, {
              storageImageRef2: storageImageRef2,
              storageImageUrl2: storageImageUrl2,
            });
          }
          else if (selectedImage2 == null && isNewImage2) {
            await updateDoc(postRef, {
              storageImageRef2: null,
              storageImageUrl2: null,
            })
          };

          if (selectedImage3 != null && isNewImage3) {
            await updateDoc(postRef, {
              storageImageRef3: storageImageRef3,
              storageImageUrl3: storageImageUrl3,
            });
          }
          else if (selectedImage3 == null && isNewImage3) {
            await updateDoc(postRef, {
              storageImageRef3: null,
              storageImageUrl3: null,
            })
          };

          await updateDoc(postRef, {
            comment: text,
            username: window.localStorage.getItem('username'),
            bar: barName,
            tagsArray: tags,
            latitude: lat,
            longitude: lng,
            geohash: hash,
            geohash2: hash.substring(0, 2),
            geohash3: hash.substring(0, 3),
            geohash4: hash.substring(0, 4),
            geohash5: hash.substring(0, 5),
            geohash6: hash.substring(0, 6),
          });
         
          console.log('Post updated');
        } 
        catch (e) {
          console.log("Error update the post: ", e);
        }
      }
     
      
      //onClose()
    } catch (err) {
      alert(err);
      setIsSubmitDisabled(false);
    }
    
    // hide activity indicator
    setAnimating(false);
    navigation.navigate({
      name: 'HomeScreen',
      params: { shouldRefresh: true },
      merge: true,
    });
  }

  const handleTagAdd = async (e) => {
    // var newTagsArray = [].concat(tags);
    // newTagsArray.push(tag);
    // setTags(newTagsArray);

    if (!tag) {
      return;
    }

    var tagArray = tag.split(',');
    console.log('tagArray', tagArray);

    let newTagsArray = null;
    for (var i = 0; i < tagArray.length; i++) {

      var newTag = tagArray[i].trim().toLowerCase().replace(/\s+/g, '');
      if (newTag.startsWith('#')) {
        newTag = newTag.substring(1);
      }

      if (newTag.length != 0 && tags.indexOf(newTag) == -1) {
        newTagsArray = newTagsArray == null ? tags.concat(newTag) : newTagsArray.concat(newTag);
      }
    }

    console.log('newTagsArray', newTagsArray);
   
   
    setTags(newTagsArray);
    setTag('');
    //ref.current.value = "";

  }

  const handleTagRemove = async (tagToRemove) => {
    //const index = tags.indexOf(tagToRemove);
    //const newTagsArray = [].concat(tags.splice(index, 1));
    var temp = [... tags];
    const newTagsArray = [].concat(temp.filter((word) => word !== tagToRemove));

    setTags(newTagsArray);
    //setTag('');

    // var newTagsArray = [].concat(tags);
    // newTagsArray.push(tag);
    // setTags(newTagsArray);

    //const newTagsArray = tags.concat(tag);
    //setTags(newTagsArray);
    //setTag('');
  }

  // function renderTags() {
  //   const elements = [];
  //   tags.map(tag => elements.push((<Text key={tag} style={{marginRight: 10, fontSize: 12, color: '#674FE0' }} onPress={() => handleTagClick(tag)}>{tag}</Text>)));
  //   return elements;

  //   /*
  //   <Chip
  //                   label={barFilter}
  //                   color="white"
  //                   //backgroundColor="#674FE0"
  //                   variant="outlined"
  //                   onPress={() => clearBarFilter()}
  //                   style={styles.filter}
  //                   leading={props => <Icon name="close" style={{color: 'white'}} {...props}/>
  //   */
  // }

  const List = ({ tags }) => (
    // <ul>
      tags.map((item) => (
        // <li key={item}>{item}</li>
        <Chip
          key={item}
          label={item}
          color="white"
          //backgroundColor="#674FE0"
          variant="outlined"
          onPress={() => handleTagRemove(item)}
          style={styles.newTag}
          leading={props => <Icon name="close" style={{ color: 'white' }} {...props} />}
        />
      ))
    // </ul>
  );

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView style={styles.container}>

        <View style={styles.imageContainer}>
          <TouchableWithoutFeedback onPress={pickImageAsync}>
            <View style={styles.imageContainer1}>
              <ImageViewer
                placeholderImageSource={PlaceholderImage}
                selectedImage={selectedImage}
              />
            </View>
          </TouchableWithoutFeedback>
          {selectedImage && <Ionicons name="md-close-circle" size={30} color={"#674FE0"} onPress={() => setSelectedImage(null)} style={{ position: 'absolute', left: -8, top: -8 }} />}
          {/* <Button theme="secondary" label="Choose a photo (optional)" onPress={pickImageAsync} /> */}
        </View>
        <View style={{ flexDirection: 'row', paddingTop: 5}}>
          <Text style={{ fontSize: 12, color: '#674FE0' }} onPress={() => setShowChooseMorePhotos(!showChooseMorePhotos)}>Choose more photos (optional)</Text>
        </View>
        {showChooseMorePhotos &&
          <View style={styles.secondaryImageRow}>
            <TouchableWithoutFeedback onPress={pickImage2Async}>
              <View style={styles.imageContainer2}>
                <ImageViewer
                  placeholderImageSource={PlaceholderImage}
                  selectedImage={selectedImage2}
                />
                {selectedImage2 && <Ionicons name="md-close-circle" size={30} color={"#674FE0"} onPress={() => { setSelectedImage2(null); setIsNewImage2(true); }} style={{ position: 'absolute', left: -8, top: -8 }} />}
              </View>
            </TouchableWithoutFeedback>
            <TouchableWithoutFeedback onPress={pickImage3Async}>
              <View style={styles.imageContainer3}>
                <ImageViewer
                  placeholderImageSource={PlaceholderImage}
                  selectedImage={selectedImage3}
                />
                {selectedImage3 && <Ionicons name="md-close-circle" size={30} color={"#674FE0"} onPress={() => { setSelectedImage3(null); setIsNewImage3(true); }} style={{ position: 'absolute', left: -8, top: -8 }} />}
              </View>
            </TouchableWithoutFeedback>
          </View>
        }
        <View style={styles.textContainer}>
          <TextInput
            style={{ height: 80, width: '100%', backgroundColor: '#fff', padding: 5, marginTop: 5, marginBottom: 5 }}
            placeholder="What's happening?"
            placeholderTextColor="gray"
            onChangeText={newText => setText(newText)}
            defaultValue={text}
            numberOfLines={5}
            textAlignVertical={"top"}
            textBreakStrategy={"highQuality"}
            multiline={true}
            maxLength={500}
          />
          {/* <TextInput
            style={{ height: 40, width: '100%', backgroundColor: '#fff', padding: 5, marginTop: 5 }}
            placeholder="What bar?"
            placeholderTextColor="gray"
            onChangeText={newText => setBar(newText)}
            defaultValue={bar}
            textAlignVertical={"center"}
            maxLength={50}
          /> */}
          <GooglePlacesAutocomplete
            placeholder='What bar?'
            ref={refBar}            
            fetchDetails={true}
            textInputProps={{
              placeholderTextColor: 'gray',
              size: 14,
              font: 'BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif'
            }}
            minLength={2} // minimum length of text to search
            onPress={(data, details = null) => {
              // 'details' is provided when fetchDetails = true
              console.log('data, details', data, details);
              setGooglePlacesDetails(details);
            }}
            query={{
              //key: 'AIzaSyBQmpsFNXkLFhuWyxeJMjUW-pk7O4xOHN4',
              language: 'en',
              lat: location ? location.coords.latitude : null,
              lng: location ? location.coords.longitude : null,
            }}
            requestUrl={{
              useOnPlatform: 'web', // or "all"
              url:
                'https://us-central1-atabar-d7693.cloudfunctions.net/placesApiSearch', // or any proxy server that hits https://maps.googleapis.com/maps/api
              // headers: {
              //   Authorization: `an auth token`, // if required for your proxy
              // },
            }}
          />
          <View style={{ flexDirection: 'row' }}>
            <TextInput
              style={{ height: 40, width: '50%', backgroundColor: '#fff', padding: 5, marginTop: 5 }}
              placeholder="Add a tag?"
              placeholderTextColor="gray"
              onChangeText={newText => setTag(newText.toLowerCase())}
              value={tag}
              textAlignVertical={"center"}
              maxLength={100}
              ref={ref}
            />
            <IconButton
              icon={props => <Icon name="plus" {...props} />}
              color="white"
              onPress={handleTagAdd}
              style={{ backgroundColor: '#674FE0', width: 40, height: 40, alignSelf: 'center', marginLeft: 5  }}
            />
          </View>
        </View>
        <View style={styles.tagsContainer}>
          {tags ? <List tags={tags} /> : <></>}
        </View>
        <View style={styles.postbuttoncontainer}>
          {/* <View> */}
            <ActivityIndicator style={{ position: 'absolute', bottom: '50%', right: '50%',}} size="large" color="#ffd33d" animating={animating} hidesWhenStopped={true} />
          {/* </View> */}
          {isUpdating == false &&
            <Button theme="primary" label="Post" onPress={handleSubmit} isDisabled={isSubmitDisabled} />
          }
          {isUpdating == true &&
            <Button theme="primary" label="Save" onPress={handleSubmit} isDisabled={isSubmitDisabled} />
          }
          <StatusBar style="auto" />
        </View>
        {/* <View style={styles.footerContainer}>

      </View> */}
      </ScrollView>
      <ConfirmModal
        message={"Are you sure you want to delete your post?"}
        visible={isDeleteConfirmVisible}
        dismiss={closePopup}
        confirmDelete={confirmDeletePost}
      ></ConfirmModal>
    </SafeAreaView>
  );
}

// todo: old view, delete
// function PostScreen() {
//     return (
//         <View style={styles.container}>
//             <View style={styles.imageContainer}>
//                 <ImageViewer
//                     placeholderImageSource={PlaceholderImage}
//                     selectedImage={selectedImage}
//                 />
//             </View>
//             <View style={styles.footerContainer}>
//                 <Button theme="primary" label="Choose a photo" onPress={pickImageAsync} />
//                 <Button label="Use this photo" />
//             </View>
//             <StatusBar style="auto" />
//         </View>
//     );
// }

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F2F2F2',
    paddingTop: 5,
    paddingLeft: 20,
    paddingRight: 20
    //alignItems: 'center',
  },
  imageContainer: {
    //paddingTop: 25,
    //alignContent: 'center',
    //width: '100%',
    width: '100%',
    height: 200,
    //alignContent: 'center',    
  },
  imageContainer1: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#E0CD67',
    //alignContent: 'center',
  },
  imageContainer2: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#E0CD67',
    //marginTop: 10, 
    marginRight: 10,
    marginLeft: 20,
    //alignContent: 'center',
  },
  imageContainer3: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#E0CD67',
    //marginTop: 10, 
    marginLeft: 10,
    marginRight: 20,
    //alignContent: 'center',
  },
  secondaryImageRow: {
    //paddingTop: 25,
    //alignContent: 'center',
    //width: '100%',
    width: '100%',
    height: 75,
    flexDirection: 'row',
    //alignContent: 'center',    
  },
  textContainer: {
    flex: 1,
    flexDirection: 'column',
    //backgroundColor: 'pink',
    //alignItems: 'center',
    //paddingBottom: 10,
  },
  tagsContainer: {
    //flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 5,
    //backgroundColor: 'pink',
    //alignItems: 'center',
    //paddingBottom: 10,
  },
  postbuttoncontainer: {    
    //flex: 1/2,
    //direction is column because of the activity indicator taking up space even when off
    flexDirection: 'column',
    //backgroundColor: 'yellow',
    //alignContent: 'top',
    paddingTop: 10,
    alignItems: 'center',
  },
  newTag: {
    alignSelf: 'center',
    backgroundColor: "#674FE0",
  }
  // footerContainer: {
  //   flex: 1,
  //   flexDirection: 'row',
  //   backgroundColor: 'green',
  // }
});
